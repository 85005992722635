import React, { useState } from 'react'
import classnames from 'classnames'
import map from 'lodash/map'
import capitalize from 'lodash/capitalize'
import firstLetterUppercase from '@Utils/FirstLetterUppercase'
import Parameter from './Parameter'
import Arrow from '@Images/arrow_pv_settings.svg'

import {
  IParameter,
  IGuidelineParameters,
  IGuideline,
} from '@SiteModules/Guideline/types'

interface ParametersProps {
  readonly parametersEdited: boolean
  readonly editedParameters: IGuidelineParameters
  readonly defaultParameters: IGuidelineParameters
  readonly activeItem:  IGuideline | null
  setParametersEdited?(parametersEdited: boolean): void
  setEditedParameters?(editedParameters: IGuidelineParameters): void
}

const DELAY_TO_SHOW_OVERFLOW = 200 // ms, The same time as for the opening/closing animations in CustomizationForm__parameters-list

const MIN_NUMBER_OF_LINES = 1
const MAX_NUMBER_OF_LINES = 3

const MIN_CPL = 16
const MAX_CPL = 50

const MIN_GAP = 1
const MAX_GAP = 6

const MIN_CPS = 15
const MAX_CPS = 30

const BASIC_PARAMETERS: IParameter[] = [{
  name: 'numberOfLines',
  title: 'Maximum number of lines',
  unit: 'lines',
  minValue: MIN_NUMBER_OF_LINES,
  maxValue: MAX_NUMBER_OF_LINES,
}, {
  name: 'cpl',
  title: 'Maximum characters per line (CPL)',
  unit: 'CPL',
  minValue: MIN_CPL,
  maxValue: MAX_CPL,
}]

const ADVANCED_PARAMETERS: IParameter[] = [{
  name: 'gap',
  title: 'Minimum gap between subtitles',
  unit: 'frames',
  minValue: MIN_GAP,
  maxValue: MAX_GAP,
}, {
  name: 'cps',
  title: 'Maximum character per second (CPS)',
  unit: 'CPS',
  minValue: MIN_CPS,
  maxValue: MAX_CPS,
}]

const Parameters: React.FC<ParametersProps> = props => {
  const {
    editedParameters,
    activeItem,
    defaultParameters,
  } = props

  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false)
  const [advancedSettingsOpenDelayed, setAdvancedSettingsOpenDelayed] = useState(false)

  const changeParameter = (name: string, value: number | boolean) => {
    const newEditedParameters = { ...editedParameters, [name]: value }
    props.setEditedParameters!(newEditedParameters)

    const isNew = !activeItem && JSON.stringify(newEditedParameters) !== JSON.stringify(defaultParameters)
    const hasChanged = !!activeItem && JSON.stringify(newEditedParameters) !== JSON.stringify(activeItem.attributes.parameters)
    const hasEditedParameters = isNew || hasChanged

    props.setParametersEdited!(hasEditedParameters)
  }

  const changeUseParameter = (name: string, value: boolean) => {
    changeParameter(`use${firstLetterUppercase(name)}`, value)
  }

  const toggleAdvancedSettings = () => {
    // https://stackoverflow.com/questions/13938460/css-transition-auto-height-not-working
    const listDiv = document.getElementById('CustomizationForm__parameters-list')
    if (!listDiv) { return }

    listDiv.clientHeight ? closeAdvancedSettings(listDiv) : openAdvancedSettings(listDiv)
  }

  const openAdvancedSettings = (listDiv: HTMLElement) => {
    const wrapper = document.querySelector('.CustomizationForm__parameters-list-inner-wrapper')
    if (wrapper) {
      listDiv.style.height = wrapper.clientHeight + 'px'
      setAdvancedSettingsOpen(true)
      setTimeout(() => {
        setAdvancedSettingsOpenDelayed(true)
      }, DELAY_TO_SHOW_OVERFLOW)
    }
  }

  const closeAdvancedSettings = (listDiv: HTMLElement) => {
    listDiv.style.height = '0'
    setAdvancedSettingsOpen(false)
    setAdvancedSettingsOpenDelayed(false)
  }

  const basicParametersList = map(BASIC_PARAMETERS, (parameter, index) => {
    const handleChange = (newValue: number) => {
      changeParameter(parameter.name, newValue)
    }

    const handleChangeUse = (newValue: boolean) => {
      changeUseParameter(parameter.name, newValue)
    }

    return (
      <li key={index}>
        <Parameter
          title={parameter.title}
          value={editedParameters[parameter.name] as number}
          useValue={editedParameters[`use${firstLetterUppercase(parameter.name)}`] as boolean}
          unit={parameter.unit}
          minValue={parameter.minValue}
          maxValue={parameter.maxValue}
          changeParameter={handleChange}
          changeUseParameter={handleChangeUse}
        />
      </li>
    )
  })

  const advancedParametersList = map(ADVANCED_PARAMETERS, (parameter, index) => {
    const handleChange = (newValue: number) => {
      changeParameter(parameter.name, newValue)
    }

    const handleChangeUse = (newValue: boolean) => {
      changeUseParameter(parameter.name, newValue)
    }

    return (
      <li key={index}>
        <Parameter
          title={parameter.title}
          value={editedParameters[parameter.name] as number}
          useValue={editedParameters[`use${capitalize(parameter.name)}`] as boolean}
          unit={parameter.unit}
          minValue={parameter.minValue}
          maxValue={parameter.maxValue}
          changeParameter={handleChange}
          changeUseParameter={handleChangeUse}
        />
      </li>
    )
  })

  const renderAdvancedSettingsHandler = () => {
    return (
      <div
        className="CustomizationForm__advanced-settings-handler"
        onClick={toggleAdvancedSettings}
      >
        {advancedSettingsOpen ? 'Hide advanced settings' : 'Show advanced settings'}
        <Arrow
          className={classnames(
            'CustomizationForm__advanced-settings-arrow',
            {'CustomizationForm__advanced-settings-arrow-open': advancedSettingsOpen})
          }
        />
      </div>
    )
  }

  const renderBasicParametersList = () => {
    return (
      <div className="CustomizationForm__parameters-list">
        <ul>
          {basicParametersList}
        </ul>
      </div>
    )
  }

  const renderAdvancedParametersList = () => {
    return (
      <div
        className={classnames(
          'CustomizationForm__parameters-list',
          'CustomizationForm__parameters-list-advanced-settings',
          {'CustomizationForm__parameters-list-advanced-settings-open': advancedSettingsOpenDelayed})
        }
        id="CustomizationForm__parameters-list"
      >
        <div className="CustomizationForm__parameters-list-inner-wrapper">
          <ul>
            {advancedParametersList}
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div className="CustomizationForm__parameters">
      <div className="CustomizationForm__parameters-title">
        Subtitle timing
      </div>
      {renderBasicParametersList()}
      {renderAdvancedParametersList()}
      {renderAdvancedSettingsHandler()}
    </div>
  )
}

export default Parameters
