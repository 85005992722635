import React, { useState, useEffect } from 'react'
import map from 'lodash/map'
import find from 'lodash/find'
import classnames from 'classnames'
import CustomizationForm from '@SiteComponents/CustomizationForm'

import {
  IGuideline,
  IGuidelineParameters,
  ICreateGuidelinePayload,
  IUpdateGuidelinePayload,
  IDeleteGuidelinePayload,
  ICreateCustomizationPayload,
  ISaveCustomizationPayload,
  IUpdateDefaultGuidelinePayload,
  IDeleteDefaultGuidelinePayload,
} from '@SiteModules/Guideline/types'

import {
  IVocabulary,
} from '@SiteModules/Vocabulary/types'

import './GuidelinesForm.scss'

interface GuidelinesFormProps {
  readonly captionGuidelines: IGuideline[]
  readonly translationGuidelines: IGuideline[]
  readonly loading: boolean
  readonly modifying: boolean
  createGuideline(payload: ICreateGuidelinePayload): void
  updateGuideline(payload: IUpdateGuidelinePayload): void
  deleteGuideline(payload: IDeleteGuidelinePayload): void
  deleteDefaultGuideline(payload: IDeleteDefaultGuidelinePayload): void
  updateDefaultGuideline(payload: IUpdateDefaultGuidelinePayload): void
}

const TABS = [
  'Caption Guidelines',
  'Translation Guidelines'
]

const CAPTION_GUIDELINES_TAB_INDEX = 0
const LINK_OFFSET_CAPTION = 288 // px
const LINK_OFFSET_TRANSLATION = 301 // px
const MAX_PROMPT_LENGTH = 50000 // characters
const MAX_NAME_LENGTH = 100 // characters

const DEFAULT_PARAMETERS: IGuidelineParameters = {
  numberOfLines: 2,
  cpl: 40,
  gap: 2,
  cps: 20,
  useNumberOfLines: true,
  useCpl: true,
  useGap: true,
  useCps: true
}

const GuidelinesForm: React.FC<GuidelinesFormProps> = props => {
  const {
    captionGuidelines,
    translationGuidelines,
    loading,
    modifying
  } = props

  const [discardChangesPopupOpen, setDiscardChangesPopupOpen] = useState(false)
  const [delayedActionType, setDelayedActionType] = useState('')
  const [delayedActionItemIndex, setDelayedActionItemIndex] = useState<number | null>(null)

  const [activeTabIndex, setActiveTabIndex] = useState(CAPTION_GUIDELINES_TAB_INDEX)

  const [activeGuideline, setActiveGuideline] = useState<IGuideline | IVocabulary | null>(null)
  const [editedPrompt, setEditedPrompt] = useState('')
  const [editedParameters, setEditedParameters] = useState(DEFAULT_PARAMETERS)

  const [editedName, setEditedName] = useState<null | string>(null)
  const [promptEdited, setPromptEdited] = useState(false)
  const [parametersEdited, setParametersEdited] = useState(false)

  const [hasSavedNameOnly, setHasSavedNameOnly] = useState(false)
  const [hasCreatedGuideline, setHasCreatedGuideline] = useState(false)

  const isBusy = loading || modifying
  const isCaptionGuidelinesTab = activeTabIndex === CAPTION_GUIDELINES_TAB_INDEX
  const relevantGuidelines = isCaptionGuidelinesTab ? captionGuidelines : translationGuidelines
  const hasRelevantGuidelines = relevantGuidelines.length > 0

  useEffect(() => {
    if (!loading) {
      if (hasCreatedGuideline) {
        handleCreating()
      } else {
        handleLoadingUpdatingDeleting()
      }
      resetState()
    }
  }, [loading])

  useEffect(() => {
    const newGuideline = hasRelevantGuidelines ? relevantGuidelines[0] : null

    changeGuideline(newGuideline)
  }, [activeTabIndex])

  const handleCreating = () => {
    const createdGuideline = relevantGuidelines.reduce((a, b) => {
      return (a.id > b.id) ? a : b
    })
    setActiveGuideline(createdGuideline)
    if (!hasSavedNameOnly) {
      setEditedPrompt(createdGuideline.attributes.prompt)
      setEditedParameters(createdGuideline.attributes.parameters)
    }
    setHasCreatedGuideline(false)
  }

  const handleLoadingUpdatingDeleting = () => {
    if (!activeGuideline) {
      const newActiveGuideline = hasRelevantGuidelines ? relevantGuidelines[0] : null
      setActiveGuideline(newActiveGuideline)

      const newEditedPrompt = newActiveGuideline ? newActiveGuideline.attributes.prompt : ''
      const newEditedParameters = newActiveGuideline ? newActiveGuideline.attributes.parameters : DEFAULT_PARAMETERS
      setEditedPrompt(newEditedPrompt)
      setEditedParameters(newEditedParameters)
    } else {
      const updatedActiveGuideline = find(relevantGuidelines, relevantGuideline => relevantGuideline.id === activeGuideline.id)
      const newActiveGuideline = updatedActiveGuideline || (hasRelevantGuidelines ? relevantGuidelines[0] : null)
      setActiveGuideline(newActiveGuideline)
      if (!hasSavedNameOnly) {
        setEditedPrompt(newActiveGuideline ? newActiveGuideline.attributes.prompt : '')
        setEditedParameters(newActiveGuideline ? newActiveGuideline.attributes.parameters : DEFAULT_PARAMETERS)
      }
    }
  }

  const resetState = () => {
    setEditedName(null)

    if (hasSavedNameOnly) {
      setHasSavedNameOnly(false)
    } else {
      setPromptEdited(false)
      setParametersEdited(false)
    }
  }

  const closeDiscardChangesPopup = () => {
    setDiscardChangesPopupOpen(false)
    setDelayedActionType('')
    setDelayedActionItemIndex(null)
  }

  const openDiscardChangesPopup = () => {
    setDiscardChangesPopupOpen(true)
  }

  const changeGuideline = (guideline: IGuideline | null) => {
    setActiveGuideline(guideline)
    setEditedPrompt(guideline ? guideline.attributes.prompt : '')
    setEditedParameters(guideline ? guideline.attributes.parameters : DEFAULT_PARAMETERS)
    setPromptEdited(false)
    setParametersEdited(false)
  }

  const delayedAction = () => {
    if (delayedActionType === 'changeTab' && typeof(delayedActionItemIndex) === 'number') {
      setActiveTabIndex(delayedActionItemIndex)
    }

    if (delayedActionType === 'changeItem' && typeof(delayedActionItemIndex) === 'number') {
      const newGuideline = find(relevantGuidelines, relevantGuideline => relevantGuideline.id === delayedActionItemIndex)
      if (newGuideline) {
        changeGuideline(newGuideline)
      }
    }

    if (delayedActionType === 'createNewItem') {
      props.createGuideline({
        category: isCaptionGuidelinesTab ? 'caption' : 'translation',
        parameters: DEFAULT_PARAMETERS
      })
      setHasCreatedGuideline(true)
    }

    if (delayedActionType === 'deleteItem' && activeGuideline) {
      if ((activeGuideline as IGuideline).attributes.isDefault) {
        props.deleteDefaultGuideline({ id: activeGuideline.id })
      } else {
        props.deleteGuideline({ id: activeGuideline.id })
      }
    }
  }

  const handleCreate = (payload: ICreateCustomizationPayload) => {
    const { content, parameters } = payload

    props.createGuideline({
      category: isCaptionGuidelinesTab ? 'caption' : 'translation',
      parameters: parameters ? parameters : DEFAULT_PARAMETERS,
      ...(content ? { prompt: content } : {})
    })

    setHasCreatedGuideline(true)
  }

  const handleSave = (payload: ISaveCustomizationPayload) => {
    const { id, name, content, defaultGuideline, parameters } = payload
    const hasContentToSave = typeof(content) === 'string'

    if (defaultGuideline) {
      props.updateDefaultGuideline({
        id,
        name: name || defaultGuideline.attributes.name,
        prompt: content || defaultGuideline.attributes.prompt || '',
        parameters: parameters || defaultGuideline.attributes.parameters,
        category: defaultGuideline.attributes.category,
        hasSavedNameOnly: !hasContentToSave && !parameters
      })

      setHasCreatedGuideline(true) // When updating a default guideline, a new guideline is created
    } else {
      props.updateGuideline({
        id,
        ...(name ? { name } : {}),
        ...(hasContentToSave ? { prompt: content } : {}),
        ...(parameters ? { parameters } : {})
      })
    }

    setHasSavedNameOnly(!hasContentToSave && !parameters)
  }

  const tabItems = map(TABS, (tab, index) => {
    const handleChangeTab = () => {
      if (isBusy) { return }

      if (index !== activeTabIndex) {
        if (promptEdited || parametersEdited) {
          setDelayedActionType('changeTab')
          setDelayedActionItemIndex(index)
          openDiscardChangesPopup()
        } else {
          setActiveTabIndex(index)
        }
      }
    }

    return (
      <li
        key={index}
        className={classnames(
          'GuidelinesForm__tabs-item', {
            'GuidelinesForm__tabs-item-active': activeTabIndex === index
          }
        )}
        onClick={handleChangeTab}
      >
        {tab}
      </li>
    )
  })

  const renderTabs = () => {
    return (
      <React.Fragment>
        <ul className="GuidelinesForm__tabs">
          {tabItems}
        </ul>
        <hr className={`GuidelinesForm__tabs-active-line GuidelinesForm__tabs-active-line-${activeTabIndex}`}/>
      </React.Fragment>
    )
  }

  const renderCustomizationForm = () => {
    const category = isCaptionGuidelinesTab ? 'captions' : 'translation'
    const placeholderText = `Give instructions to improve the ${category} you get. You can explain the type of ${category} that you want. View`
    const linkOffset = isCaptionGuidelinesTab ? LINK_OFFSET_CAPTION : LINK_OFFSET_TRANSLATION

    return (
      <CustomizationForm
        items={relevantGuidelines}
        placeholderText={placeholderText}
        contentEdited={promptEdited}
        parametersEdited={parametersEdited}
        activeItem={activeGuideline}
        editedContent={editedPrompt}
        editedParameters={editedParameters}
        editedName={editedName}
        openDiscardChangesPopup={openDiscardChangesPopup}
        closeDiscardChangesPopup={closeDiscardChangesPopup}
        setDelayedActionType={setDelayedActionType}
        setDelayedActionItemIndex={setDelayedActionItemIndex}
        setContentEdited={setPromptEdited}
        setParametersEdited={setParametersEdited}
        setActiveItem={setActiveGuideline}
        setEditedContent={setEditedPrompt}
        setEditedParameters={setEditedParameters}
        setEditedName={setEditedName}
        linkOffset={linkOffset}
        isBusy={isBusy}
        maxContentLength={MAX_PROMPT_LENGTH}
        maxNameLength={MAX_NAME_LENGTH}
        create={handleCreate}
        save={handleSave}
        discardChangesPopupOpen={discardChangesPopupOpen}
        delayedAction={delayedAction}
        defaultParameters={DEFAULT_PARAMETERS}
      />
    )
  }

  return (
    <React.Fragment>
      {renderTabs()}
      {renderCustomizationForm()}
    </React.Fragment>
  )
}

export default GuidelinesForm
