import {
  GuidelineActionTypes,
  IFetchGuidelinesSuccessResponseBody,
  IUpdateGuidelinePayload,
  IDeleteGuidelinePayload,
  ICreateGuidelinePayload,
  IUpdateDefaultGuidelinePayload,
  IDeleteDefaultGuidelinePayload,
} from './types'

export const fetchGuidelines = () => ({
  type: GuidelineActionTypes.FETCH_GUIDELINES,
})

export const fetchGuidelinesSuccess = (payload: IFetchGuidelinesSuccessResponseBody) => ({
  type: GuidelineActionTypes.FETCH_GUIDELINES_SUCCESS,
  payload,
})

export const fetchGuidelinesFailure = () => ({
  type: GuidelineActionTypes.FETCH_GUIDELINES_FAILURE,
})

export const createGuideline = (payload: ICreateGuidelinePayload) => ({
  type: GuidelineActionTypes.CREATE_GUIDELINE,
  payload,
})

export const createGuidelineFailure = () => ({
  type: GuidelineActionTypes.CREATE_GUIDELINE_FAILURE,
})

export const createGuidelineSuccess = () => ({
  type: GuidelineActionTypes.CREATE_GUIDELINE_SUCCESS,
})

export const updateGuideline = (payload: IUpdateGuidelinePayload) => ({
  type: GuidelineActionTypes.UPDATE_GUIDELINE,
  payload,
})

export const updateGuidelineSuccess = () => ({
  type: GuidelineActionTypes.UPDATE_GUIDELINE_SUCCESS,
})

export const updateGuidelineFailure = () => ({
  type: GuidelineActionTypes.UPDATE_GUIDELINE_FAILURE,
})

export const deleteGuideline = (payload: IDeleteGuidelinePayload) => ({
  type: GuidelineActionTypes.DELETE_GUIDELINE,
  payload,
})

export const deleteGuidelineSuccess = () => ({
  type: GuidelineActionTypes.DELETE_GUIDELINE_SUCCESS,
})

export const deleteGuidelineFailure = () => ({
  type: GuidelineActionTypes.DELETE_GUIDELINE_FAILURE,
})

export const updateDefaultGuideline = (payload: IUpdateDefaultGuidelinePayload) => ({
  type: GuidelineActionTypes.UPDATE_DEFAULT_GUIDELINE,
  payload,
})

export const updateDefaultGuidelineSuccess = () => ({
  type: GuidelineActionTypes.UPDATE_DEFAULT_GUIDELINE_SUCCESS,
})

export const updateDefaultGuidelineFailure = () => ({
  type: GuidelineActionTypes.UPDATE_DEFAULT_GUIDELINE_FAILURE,
})

export const deleteDefaultGuideline = (payload: IDeleteDefaultGuidelinePayload) => ({
  type: GuidelineActionTypes.DELETE_DEFAULT_GUIDELINE,
  payload,
})

export const deleteDefaultGuidelineSuccess = () => ({
  type: GuidelineActionTypes.DELETE_DEFAULT_GUIDELINE_SUCCESS,
})

export const deleteDefaultGuidelineFailure = () => ({
  type: GuidelineActionTypes.DELETE_DEFAULT_GUIDELINE_FAILURE,
})
