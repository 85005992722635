import React from 'react'
import DropdownList from '@checksub_team/react-widgets/lib/DropdownList'
import Checkbox from './Checkbox'

import './Vocabularies.scss'

interface VocabulariesProps {
  readonly vocabularyChecked: boolean
  readonly vocabularyNames: string[]
  readonly selectedVocabularyName: string
  setVocabularyChecked(newSetVocabularyChecked: boolean): void
  setSelectedVocabularyName(newVocabularyName: string): void
}

const Vocabularies: React.FC<VocabulariesProps> = props => {
  const {
    vocabularyNames,
    vocabularyChecked,
    setVocabularyChecked,
    selectedVocabularyName,
    setSelectedVocabularyName,
  } = props

  const vocabulariesPresent = vocabularyNames.length > 0

  const vocabularyNamesWithOptionForNoList = ['Do not use any list'].concat(vocabularyNames)

  const renderVocabularyDetails = () => {
    return (
      <div className="ProjectVersionSettingsForm__formfield Vocabularies__formfield">
        {vocabulariesPresent ? (
          <DropdownList
            placeholder="Select a vocabulary list..."
            data={vocabularyNamesWithOptionForNoList}
            id="vocabularyName"
            name="vocabularyName"
            value={selectedVocabularyName}
            onChange={setSelectedVocabularyName}
          />
        ) : (
          <div className="Vocabularies__create-list">
            <a href="/customizations/vocabularies">Create a list of custom words</a>
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <Checkbox
        checked={vocabularyChecked}
        setChecked={setVocabularyChecked}
        label="Use custom vocabulary to improve quality"
      />
      {vocabularyChecked && renderVocabularyDetails()}
    </div>
  )
}

export default Vocabularies
