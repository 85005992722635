import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import map from 'lodash/map'
import { v4 as uuidv4 } from 'uuid'
import useClickOutsideListener from '@Utils/UseClickOutsideListener'
import * as Page from '@Utils/BrowserDimensions'

import Checkmark from '@Images/dropdown_checkmark.svg'
import DropdownArrow from '@Images/dropdown_arrow_checkbox_v2.svg'

import './DropdownV2.scss'

interface DropdownV2Props {
  readonly items: string[]
  readonly itemsToShow?: JSX.Element[]
  readonly currentItem: string
  readonly shouldBlockDropdown?: boolean
  readonly tooltip?: string
  readonly listWidth?: number
  readonly classNameOfContainerToMakeUnscrollable?: string
  readonly paddingToCompensateScrollbarWhenUnscrollable?: number
  readonly noPortal?: boolean
  readonly dropdownX?: number // Must be set for noPortal
  readonly dropdownY?: number // Must be set for noPortal
  readonly isForLipSync?: boolean
  onChange(newItem: string): void
}

const DROPDOWN_WIDTH = 228 // must be the same as in Dropdown.scss
const DROPDOWN_HEIGHT = 196 // must be the same as in Dropdown.scss
const DROPDOWN_MARGIN_TOP = 10

const DropdownV2: React.FC<DropdownV2Props> = props => {
  const {
    items,
    noPortal,
    tooltip,
    listWidth,
    itemsToShow,
    currentItem,
    isForLipSync,
    shouldBlockDropdown,
    classNameOfContainerToMakeUnscrollable,
    paddingToCompensateScrollbarWhenUnscrollable,
  } = props

  const hasNoDropdown = items.length === 1

  const [openDropdown, setOpenDropdown] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [infoTimeout, setInfoTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)
  const dropdownButtonRef = useRef<HTMLDivElement | null>(null)
  const shouldShowInfo = tooltip && showInfo && !openDropdown
  const showItemsAsJsx = !!itemsToShow

  const dropdownUuid = uuidv4()

  const openOrCloseDropdown = (newOpenDropdown: boolean)  => {
    if (hasNoDropdown || shouldBlockDropdown) { return }

    setOpenDropdown(newOpenDropdown)

    if (classNameOfContainerToMakeUnscrollable) {
      const container = document.getElementsByClassName(classNameOfContainerToMakeUnscrollable)
      if (container && container.length > 0) {
        const containerElement = container[0] as HTMLElement
        containerElement.style.overflowY = newOpenDropdown ? 'hidden' : 'scroll'
        containerElement.style.paddingRight = newOpenDropdown ? paddingToCompensateScrollbarWhenUnscrollable + 'px' : '0px'
      }
    }
  }

  useClickOutsideListener(`DropdownV2-${dropdownUuid}`, openOrCloseDropdown, openDropdown)

  const toggleShowDropdown = () => {
    if (hasNoDropdown) { return }

    const newOpenDropdown = !openDropdown

    openOrCloseDropdown(newOpenDropdown)
  }

  const toggleShowInfo = () => {
    if (!tooltip) { return }

    if (infoTimeout) {
      setShowInfo(false)
      clearTimeout(infoTimeout)
      setInfoTimeout(null)
    } else {
      const newInfoTimeout = setTimeout(() => {
        setShowInfo(true)
      }, window.EditorSources.tooltipDelay)
      setInfoTimeout(newInfoTimeout)
    }
  }

  const itemList = map(items, (value, index) => {
    const isCurrentItem = value === currentItem

    const handleChangeItem = () => {
      const itemChanged = currentItem !== value
      if (itemChanged) {
        props.onChange(value)
      }

      openOrCloseDropdown(false)
    }

    return (
      <li
        key={index}
        className={classnames(
          'DropdownV2__item',
          { 'DropdownV2__item-lip-sync': isForLipSync },
          { 'DropdownV2__item-current': isCurrentItem }
        )}
        onClick={handleChangeItem}
      >
        {showItemsAsJsx ? itemsToShow[index] : value}
        {isCurrentItem && (
          <Checkmark />
        )}
      </li>
    )
  })

  const renderItemList = () => {
    const dropdownButtonBounds = dropdownButtonRef.current!.getBoundingClientRect()

    const newDropdownWidth = listWidth ?? DROPDOWN_WIDTH

    const dropdownX = noPortal ? props.dropdownX : Math.min(
      dropdownButtonBounds.x + dropdownButtonBounds.width - newDropdownWidth,
      Page.getWidth() - newDropdownWidth
    )
    const dropdownY = noPortal ? props.dropdownY : Math.min(
      dropdownButtonBounds.y + dropdownButtonBounds.height + DROPDOWN_MARGIN_TOP,
      Page.getHeight() - DROPDOWN_HEIGHT
    )

    const style = {
      top: `${dropdownY}px`,
      left: `${dropdownX}px`,
      width: listWidth
    }

    if (noPortal) {
      return (
        <ul
          className={`DropdownV2__list DropdownV2-${dropdownUuid}`}
          style={style}
        >
          {itemList}
        </ul>
      )
    } else {
      return ReactDOM.createPortal(
        (
          <ul
            className={`DropdownV2__list DropdownV2-${dropdownUuid}`}
            style={style}
          >
            {itemList}
          </ul>
        ),
        document.getElementById('editor-container')!
      )
    }
  }

  const renderDropdown = () => {
    const noDropdown = hasNoDropdown || shouldBlockDropdown
    const canFindDropdownButton = !!dropdownButtonRef.current

    return (
      <div className={classnames(
          'DropdownV2',
          `DropdownV2-${dropdownUuid}`
        )}
      >
        <div className={classnames(
          'DropdownV2__current',
          { 'DropdownV2__current-active': openDropdown },
          { 'DropdownV2__current-no-dropdown': noDropdown },
          { 'DropdownV2__current-disabled': shouldBlockDropdown },
        )}
          onClick={toggleShowDropdown}
          onMouseEnter={toggleShowInfo}
          onMouseLeave={toggleShowInfo}
          ref={dropdownButtonRef}
        >
          <span>{currentItem}</span>
          {!noDropdown && (
            <DropdownArrow />
          )}

          {shouldShowInfo && (
            <div className="DropdownV2__tooltip">
              {tooltip}
            </div>
          )}
        </div>

        {openDropdown && canFindDropdownButton && renderItemList()}
      </div>
    )
  }

  return (
    <React.Fragment>
      {renderDropdown()}
    </React.Fragment>
  )
}

export default DropdownV2
