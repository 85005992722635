import { IAction, IActionSuccess } from '@Root/types'

export const TermbaseActionTypes = {
  FETCH_TERMBASES: '@@termbase/FETCH_TERMBASES',
  FETCH_TERMBASES_FAILURE: '@@termbase/FETCH_TERMBASES_FAILURE',
  FETCH_TERMBASES_SUCCESS: '@@termbase/FETCH_TERMBASES_SUCCESS',
  FETCH_TERMBASE_ENTRIES: '@@termbase/FETCH_TERMBASE_ENTRIES',
  FETCH_TERMBASE_ENTRIES_FAILURE: '@@termbase/FETCH_TERMBASE_ENTRIES_FAILURE',
  FETCH_TERMBASE_ENTRIES_SUCCESS: '@@termbase/FETCH_TERMBASE_ENTRIES_SUCCESS',
  SET_ACTIVE_TERMBASE_ID: '@@termbase/SET_ACTIVE_TERMBASE_ID',
  SET_ACTIVE_PAGE: '@@termbase/SET_ACTIVE_PAGE',
  RESET_TERMBASE_ENTRIES: '@@termbase/RESET_TERMBASE_ENTRIES',
  SET_SEARCH_TERM: '@@termbase/SET_SEARCH_TERM',
  CREATE_TERMBASE: '@@termbase/CREATE_TERMBASE',
  CREATE_TERMBASE_FAILURE: '@@termbase/CREATE_TERMBASE_FAILURE',
  CREATE_TERMBASE_SUCCESS: '@@termbase/CREATE_TERMBASE_SUCCESS',
  UPDATE_TERMBASE: '@@termbase/UPDATE_TERMBASE',
  UPDATE_TERMBASE_FAILURE: '@@termbase/UPDATE_TERMBASE_FAILURE',
  UPDATE_TERMBASE_SUCCESS: '@@termbase/UPDATE_TERMBASE_SUCCESS',
  UPDATE_TERMBASE_ENTRY: '@@termbase/UPDATE_TERMBASE_ENTRY',
  UPDATE_TERMBASE_ENTRY_FAILURE: '@@termbase/UPDATE_TERMBASE_ENTRY_FAILURE',
  UPDATE_TERMBASE_ENTRY_SUCCESS: '@@termbase/UPDATE_TERMBASE_ENTRY_SUCCESS',
  DELETE_TERMBASE: '@@termbase/DELETE_TERMBASE',
  DELETE_TERMBASE_FAILURE: '@@termbase/DELETE_TERMBASE_FAILURE',
  DELETE_TERMBASE_SUCCESS: '@@termbase/DELETE_TERMBASE_SUCCESS',
  DELETE_TERMBASE_ENTRY: '@@termbase/DELETE_TERMBASE_ENTRY',
  DELETE_TERMBASE_ENTRY_FAILURE: '@@termbase/DELETE_TERMBASE_ENTRY_FAILURE',
  DELETE_TERMBASE_ENTRY_SUCCESS: '@@termbase/DELETE_TERMBASE_ENTRY_SUCCESS',
  ADD_TERMBASE_ENTRY: '@@termbase/ADD_TERMBASE_ENTRY',
  ADD_TERMBASE_ENTRY_SUCCESS: '@@termbase/ADD_TERMBASE_ENTRY_SUCCESS',
  ADD_TERMBASE_ENTRY_FAILURE: '@@termbase/ADD_TERMBASE_ENTRY_FAILURE',
}

export interface ITermbaseState {
  readonly termbases: ITermbase[]
  readonly termbaseEntries: ITermbaseEntry[]
  readonly activeTermbaseId?: number
  readonly activePage: number
  readonly totalPages: number
  readonly totalTermbaseEntryCount: number
  readonly searchTerm?: string
  readonly loadingTermbases: boolean
  readonly loadingTermbaseEntries: boolean
  readonly modifying: boolean
  readonly allFullnameLanguages: string[]
}

export interface ITermbase {
  readonly id: number
  readonly attributes: ITermbaseAttributes
}

export interface ITermbaseAttributes {
  readonly name: string
  readonly languages: string[]
  readonly fullnameLanguages: string[]
}

export interface ITermbaseEntry {
  id: number,
  attributes: ITermbaseEntryAttributes
}

export interface ITermbaseEntryAttributes {
  data: ITermbaseEntryData[]
}

export interface ITermbaseEntryData {
  language: string
  term?: string
  pronunciation?: string
  updatedAt: string
}

export interface IFetchTermbasesSuccessResponseBody {
  readonly data: IFetchTermbasesSuccessResponseData[]
  readonly allFullnameLanguages: string[]
}

export interface IFetchTermbasesSuccessResponseData {
  readonly id: string
  readonly type: 'termbase'
  readonly attributes: ITermbaseAttributes
}

export interface IFetchTermbaseEntriesPayload {
  readonly termbaseId: number
  readonly page: number
  readonly searchTerm?: string
}

export interface IFetchTermbaseEntriesSuccessResponseBody {
  readonly data: IFetchTermbaseEntriesSuccessResponseData[]
  readonly totalCount: number
  readonly totalPages: number
}

export interface IFetchTermbaseEntriesSuccessResponseData {
  readonly id: string
  readonly type: 'termbaseEntry'
  readonly attributes: ITermbaseEntryAttributes
}

export interface ISetActiveTermbaseIdPayload {
  activeTermbaseId?: number
}

export interface ISetActivePagePayload {
  activePage: number
}

export interface ISetSearchTermPayload {
  searchTerm: string
}

export interface ICreateTermbasePayload {
  name: string,
  languages: string[]
}

export interface IUpdateTermbasePayload {
  readonly id: number
  readonly name: string
  readonly languages: string[]
}

export interface IDeleteTermbasePayload {
  readonly id: number
}

export interface ISaveTermbasePayload {
  name: string,
  languages: string[]
}

export interface IAddTermbaseEntryPayload {
  readonly id: number
}

export interface IUpdateTermbaseEntryPayload {
  readonly termbaseEntryId: number
  readonly entryDataItemIndex: number
  readonly term?: string
  readonly pronunciation?: string
}

export interface IDeleteTermbaseEntryPayload {
  id: number
}

export type TTermbaseActionTypes =
  IActionSuccess<IFetchTermbasesSuccessResponseBody> |
  IActionSuccess<IFetchTermbaseEntriesSuccessResponseBody> |
  IAction<ISetActiveTermbaseIdPayload> |
  IAction<ISetActivePagePayload> |
  IAction<ISetSearchTermPayload>
