import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  IUpdateGuidelinePayload,
  IDeleteGuidelinePayload,
  ICreateGuidelinePayload,
  IUpdateDefaultGuidelinePayload,
  IDeleteDefaultGuidelinePayload,
} from './types'

const config: AxiosRequestConfig = {
  headers: {
    'X-CSRF-Token': window.Checksub.token
  },
  responseType: 'json'
}

export function fetchGuidelines() {
  return axios.get(`/api/v1/guidelines/`)
}

export function createGuideline(payload: ICreateGuidelinePayload) {
  return axios.post(`/api/v1/guidelines/`, convertToSnakeCase(payload), config)
}

export function updateGuideline(payload: IUpdateGuidelinePayload) {
  return axios.put(`/api/v1/guidelines/${payload.id}`, convertToSnakeCase(payload), config)
}

export function deleteGuideline(payload: IDeleteGuidelinePayload) {
  return axios.delete(`/api/v1/guidelines/${payload.id}`, config)
}

export function updateDefaultGuideline(payload: IUpdateDefaultGuidelinePayload) {
  return axios.put(`/api/v1/guidelines/${payload.id}/update_default`, convertToSnakeCase(payload), config)
}

export function deleteDefaultGuideline(payload: IDeleteDefaultGuidelinePayload) {
  return axios.delete(`/api/v1/guidelines/${payload.id}/destroy_default`, config)
}
