import React, { useEffect } from 'react'
import Popup from '@SiteComponents/Popup'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import AttentionIcon from '@Images/attention2.svg'

import './WarningPopup.scss'

interface WarningPopupProps {
  readonly header: string
  readonly description: string
  readonly captionButton1: string
  readonly captionButton2: string
  readonly useEnterToConfirm?: boolean
  closePopup(): void
  delayedAction(): void
}

const WarningPopup: React.FC<WarningPopupProps> = props => {
  const {
    header,
    description,
    captionButton1,
    captionButton2,
    useEnterToConfirm,
  } = props

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  const handleKeydown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter' || !useEnterToConfirm) { return }

    handleDiscard()
  }

  const handleDiscard = () => {
    props.delayedAction()
    props.closePopup()
  }

  return (
    <Popup
      className="WarningPopup"
      closePopup={props.closePopup}
    >
      <div className="WarningPopup__header">
        <AttentionIcon />
        {header}
      </div>
      <div className="WarningPopup__description">
        {description}
      </div>
      <div className="WarningPopup__button-wrapper">
        <ButtonNewV2
          styleType="neutral-secondary"
          size="small"
          caption={captionButton1}
          onClick={props.closePopup}
        />
        <ButtonNewV2
          styleType="brand-primary"
          size="small"
          caption={captionButton2}
          onClick={handleDiscard}
        />
      </div>
    </Popup>
  )
}

export default WarningPopup
