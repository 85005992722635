import map from 'lodash/map'

import { createSelector } from 'reselect'

import {
  ITermbase,
  ITermbaseState
} from './types'

export const getTermbases = (state: ITermbaseState): ITermbase[] => state.termbases

export const getTermbaseNames = createSelector(
  [getTermbases],
  (termbases) => map(termbases, termbase => termbase.attributes.name)
)

export const getTermbaseIds = createSelector(
  [getTermbases],
  (termbases) => map(termbases, termbase => termbase.id)
)
