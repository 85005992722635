import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'
import 'toastr/build/toastr.css'

import Logger from '@Utils/Logger'
import { IAction, ISuccessResponse } from '@Root/types'

import {
  fetchGuidelines,
  fetchGuidelinesSuccess,
  fetchGuidelinesFailure,
  createGuidelineSuccess,
  createGuidelineFailure,
  updateGuidelineSuccess,
  updateGuidelineFailure,
  deleteGuidelineSuccess,
  deleteGuidelineFailure,
  updateDefaultGuidelineSuccess,
  updateDefaultGuidelineFailure,
  deleteDefaultGuidelineSuccess,
  deleteDefaultGuidelineFailure,
} from './actions'

import {
  GuidelineActionTypes,
  IFetchGuidelinesSuccessResponseBody,
  ICreateGuidelinePayload,
  IUpdateGuidelinePayload,
  IDeleteGuidelinePayload,
  IUpdateDefaultGuidelinePayload,
  IDeleteDefaultGuidelinePayload,
} from './types'

import * as API from './api'

const call: any = Eff.call

function* fetchGuidelinesSaga() {
  try {
    const response: ISuccessResponse<IFetchGuidelinesSuccessResponseBody> = yield call(API.fetchGuidelines)
    const payloadResponse = response.data
    yield put(fetchGuidelinesSuccess(payloadResponse))
  } catch (e) {
    Logger.error(e, 'Guidelines could not be loaded')
    yield put(fetchGuidelinesFailure())
  }
}

function* createGuidelineSaga(action: IAction<ICreateGuidelinePayload>) {
  try {
    const { payload } = action
    yield call(API.createGuideline, payload)
    yield put(createGuidelineSuccess())
    toastr.success('', 'Guideline created')
    yield put(fetchGuidelines())
  } catch (e) {
    Logger.error(e, 'Guideline could not be created')
    yield put(createGuidelineFailure())
  }
}

function* updateGuidelineSaga(action: IAction<IUpdateGuidelinePayload>) {
  try {
    const { payload } = action
    yield call(API.updateGuideline, payload)
    yield put(updateGuidelineSuccess())
    if (payload.prompt || payload.parameters) {
      toastr.success('', 'Changes saved')
    } else {
      toastr.success('', 'Name saved')
    }
    yield put(fetchGuidelines())
  } catch (e) {
    Logger.error(e, 'Changes could not be saved')
    yield put(updateGuidelineFailure())
  }
}

function* deleteGuidelineSaga(action: IAction<IDeleteGuidelinePayload>) {
  try {
    const { payload } = action
    yield call(API.deleteGuideline, payload)
    yield put(deleteGuidelineSuccess())
    toastr.success('', 'Guideline deleted')
    yield put(fetchGuidelines())
  } catch (e) {
    Logger.error(e, 'Guideline could not be deleted')
    yield put(deleteGuidelineFailure())
  }
}

function* updateDefaultGuidelineSaga(action: IAction<IUpdateDefaultGuidelinePayload>) {
  try {
    const { payload } = action
    yield call(API.updateDefaultGuideline, payload)
    yield put(updateDefaultGuidelineSuccess())
    if (payload.hasSavedNameOnly) {
      toastr.success('', 'Name saved')
    } else {
      toastr.success('', 'Changes saved')
    }
    yield put(fetchGuidelines())
  } catch (e) {
    Logger.error(e, 'Changes could not be saved')
    yield put(updateDefaultGuidelineFailure())
  }
}

function* deleteDefaultGuidelineSaga(action: IAction<IDeleteDefaultGuidelinePayload>) {
  try {
    const { payload } = action
    yield call(API.deleteDefaultGuideline, payload)
    yield put(deleteDefaultGuidelineSuccess())
    toastr.success('', 'Guideline deleted')
    yield put(fetchGuidelines())
  } catch (e) {
    Logger.error(e, 'Guideline could not be deleted')
    yield put(deleteDefaultGuidelineFailure())
  }
}

function* guidelineSagas() {
  yield takeEvery(GuidelineActionTypes.FETCH_GUIDELINES, fetchGuidelinesSaga)
  yield takeEvery(GuidelineActionTypes.CREATE_GUIDELINE, createGuidelineSaga)
  yield takeEvery(GuidelineActionTypes.UPDATE_GUIDELINE, updateGuidelineSaga)
  yield takeEvery(GuidelineActionTypes.DELETE_GUIDELINE, deleteGuidelineSaga)
  yield takeEvery(GuidelineActionTypes.UPDATE_DEFAULT_GUIDELINE, updateDefaultGuidelineSaga)
  yield takeEvery(GuidelineActionTypes.DELETE_DEFAULT_GUIDELINE, deleteDefaultGuidelineSaga)
}

export default guidelineSagas
