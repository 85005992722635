import React, { useEffect, useRef } from 'react'
import map from 'lodash/map'
import find from 'lodash/find'
import hasElementOrSomeParentTheClass from '@Utils/HasElementOrSomeParentTheClass'
import toastr from 'toastr'
import classnames from 'classnames'

import Delete from '@Images/delete_termbase_entry.svg'
import CancelEditing from '@Images/cancel_editing.svg'
import SaveInput from '@Images/save_input.svg'

import {
  ITermbaseEntry,
  IUpdateTermbaseEntryPayload,
} from '@SiteModules/Termbase/types'

import './TermbasesTable.scss'

interface TermbasesTableProps {
  readonly termbaseEntries: ITermbaseEntry[]
  readonly editedTerm: string | null
  readonly editedPronunciation: string | null
  readonly editedTermbaseEntryId: number | null
  readonly editedTermbaseEntryDataItemIndex: number | null
  setEditedTerm(editedTerm: string | null): void
  setEditedPronunciation(editedPronunciation: string | null): void
  setEditedTermbaseEntryId(editedTermBaseEntryId: number | null): void
  setEditedTermbaseEntryDataItemIndex(editedTermBaseEntryDateItemIndex: number | null): void
  updateTermbaseEntry(payload: IUpdateTermbaseEntryPayload): void
  deleteTermbaseEntry(termbaseEntryId: number): void
  setKeepAfterLoading(keepAfterLoading: string | null): void
}

const MAX_TERM_LENGTH = 500 // characters
const MAX_PRONUNCIATION_LENGTH = 500 // characters

const TermbasesTable: React.FC<TermbasesTableProps> = props => {
  const {
    termbaseEntries,
    editedTerm,
    editedPronunciation,
    editedTermbaseEntryId,
    editedTermbaseEntryDataItemIndex,
  } = props

  const editedTermRef = useRef(editedTerm)
  const editedPronunciationRef = useRef(editedPronunciation)
  const editedTermbaseEntryIdRef = useRef(editedTermbaseEntryId)
  const editedTermbaseEntryDataItemIndexRef = useRef(editedTermbaseEntryDataItemIndex)
  const termbaseEntriesRef = useRef(termbaseEntries)

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    editedTermRef.current = editedTerm
  }, [editedTerm])

  useEffect(() => {
    editedPronunciationRef.current = editedPronunciation
  }, [editedPronunciation])

  useEffect(() => {
    editedTermbaseEntryIdRef.current = editedTermbaseEntryId
  }, [editedTermbaseEntryId])

  useEffect(() => {
    editedTermbaseEntryDataItemIndexRef.current = editedTermbaseEntryDataItemIndex
  }, [editedTermbaseEntryDataItemIndex])

  useEffect(() => {
    termbaseEntriesRef.current = termbaseEntries
  }, [termbaseEntries])

  const handleKeydown = (e: KeyboardEvent) => {
    if (e.code !== 'Enter' || (typeof(editedTermRef.current) !== 'string' && typeof(editedPronunciationRef.current) !== 'string')) { return }

    if ((typeof(editedTermRef.current) === 'string')) {
      handleSaveEditedTerm()
    } else {
      handleSaveEditedPronunciation()
    }
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (typeof(editedTermRef.current) !== 'string' && typeof(editedPronunciationRef.current) !== 'string') { return }

    if (e.target) {
      const element = e.target as HTMLElement
      if (hasElementOrSomeParentTheClass(element, 'TermbasesTable__input-wrapper')) {
        return
      } else {
        if (hasElementOrSomeParentTheClass(element, 'TermbasesTable__term-inner-wrapper')) {
          props.setKeepAfterLoading('term')
        }

        if (hasElementOrSomeParentTheClass(element, 'TermbasesTable__pronunciation-inner-wrapper')) {
          props.setKeepAfterLoading('pronunciation')
        }

        if (typeof(editedTermRef.current) === 'string') {
          handleSaveEditedTerm()
        } else {
          handleSaveEditedPronunciation()
        }
      }
    }
  }

  const changeEditedTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setEditedTerm(e.target.value)
  }

  const changeEditedPronunciation = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setEditedPronunciation(e.target.value)
  }

  const cancelEditing = () => {
    props.setEditedTerm(null)
    props.setEditedPronunciation(null)
    props.setEditedTermbaseEntryDataItemIndex(null)
  }

  const shouldContinueAfterCheck = (newEditedItem: string, lengthLimit: number, itemName: string) => {
    const itemTooLong = newEditedItem.length > lengthLimit

    if (!itemTooLong) { return true }

    const message = `Maximum ${itemName} length is ${lengthLimit} characters`

    toastr.error(
      message,
      `Cannot save ${itemName}`,
    )

    return false
  }

  const handleSaveEditedTerm = () => {
    const newEditedTerm = (editedTermRef.current as string).trim()
    const term = find(termbaseEntriesRef.current, termbaseEntry => termbaseEntry.id === editedTermbaseEntryIdRef.current)

    if (term && typeof(editedTermbaseEntryDataItemIndexRef.current) === 'number') {
      const dataItem = term.attributes.data[editedTermbaseEntryDataItemIndexRef.current]
      const shouldSaveTerm = (dataItem.term !== newEditedTerm) && !(newEditedTerm.length === 0  && !dataItem.term) // Do not save if the term was undefined and now an empty string is entered for it

      if (shouldSaveTerm) {
        if (shouldContinueAfterCheck(newEditedTerm, MAX_TERM_LENGTH, 'term')) {
          props.updateTermbaseEntry({ termbaseEntryId: editedTermbaseEntryIdRef.current as number, term: newEditedTerm as string, entryDataItemIndex: editedTermbaseEntryDataItemIndexRef.current as number })
        }
      } else {
        cancelEditing()
      }
    } else {
      cancelEditing()
    }
  }

  const handleSaveEditedPronunciation = () => {
    const newEditedPronunciation = (editedPronunciationRef.current as string).trim()
    const pronunciation = find(termbaseEntriesRef.current, termbaseEntry => termbaseEntry.id === editedTermbaseEntryIdRef.current)

    if (pronunciation && typeof(editedTermbaseEntryDataItemIndexRef.current) === 'number') {
      const dataItem = pronunciation.attributes.data[editedTermbaseEntryDataItemIndexRef.current]
      const shouldSavePronunciation = (dataItem.pronunciation !== newEditedPronunciation) && !(newEditedPronunciation.length === 0  && !dataItem.pronunciation) // Do not save if the pronunciation was undefined and now an empty string is entered for it

      if (shouldSavePronunciation) {
        if (shouldContinueAfterCheck(newEditedPronunciation, MAX_PRONUNCIATION_LENGTH, 'pronunciation')) {
          props.updateTermbaseEntry({ termbaseEntryId: editedTermbaseEntryIdRef.current as number, pronunciation: newEditedPronunciation as string, entryDataItemIndex: editedTermbaseEntryDataItemIndexRef.current as number })
        }
      } else {
        cancelEditing()
      }
    } else {
      cancelEditing()
    }
  }

  const renderTableHead = () => {
    return (
      <thead className="TermbasesTable__header">
        <tr>
          <th className="TermbasesTable__header-language">
            Language
          </th>
          <th className="TermbasesTable__header-term">
            Term
          </th>
          <th className="TermbasesTable__header-pronunciation">
            Pronunciation
          </th>
          <th className="TermbasesTable__header-updated-at">
            Last Modified
          </th>
          <th className="TermbasesTable__header-delete"/>
        </tr>
      </thead>
    )
  }

  const renderTableBody = () => {
    return (
      <tbody className="TermbasesTable__body">
        {tableBody}
      </tbody>
    )
  }

  const tableBody = map(termbaseEntries, termbaseEntry => {
    const language = map(termbaseEntry.attributes.data, (dataItem, index) => {
      const languageColorIndex = index % window.Checksub.termbaseLanguageColors.length
      const languageColor = window.Checksub.termbaseLanguageColors[languageColorIndex]

      return (
        <div
          key={index}
          className="TermbasesTable__language-outer-wrapper"
        >
          <div className="TermbasesTable__language-inner-wrapper">
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse cx="4.00006" cy="4" rx="4" ry="4" fill={languageColor}/>
            </svg>
            <span
              className="TermbasesTable__language-text"
              style={{ color: languageColor }}
            >
              {dataItem.language}
            </span>
          </div>
        </div>
      )
    })

    const term = map(termbaseEntry.attributes.data, (dataItem, index) => {
      const isEditing = typeof(editedTerm) === 'string' && editedTermbaseEntryId === termbaseEntry.id && editedTermbaseEntryDataItemIndex === index
      const termIsEmpty = !dataItem.term || dataItem.term.length === 0
      const pronunciationIsNotEmpty = dataItem.pronunciation && dataItem.pronunciation.length > 0
      const shouldShowPlaceholder = termIsEmpty && pronunciationIsNotEmpty

      const startEditingTerm = () => {
        props.setEditedTerm(dataItem.term || '')
        props.setEditedTermbaseEntryId(termbaseEntry.id)
        props.setEditedTermbaseEntryDataItemIndex(index)
      }

      const renderTerm = () => {
        return (
          <div
            className={classnames(
              'TermbasesTable__term-inner-wrapper', {
                'TermbasesTable__term-inner-wrapper-placeholder': shouldShowPlaceholder
              }, {
                'TermbasesTable__term-inner-wrapper-empty': termIsEmpty && !shouldShowPlaceholder
              }
            )}
            onClick={startEditingTerm}
          >
            {shouldShowPlaceholder ? 'Enter term to use the pronunciation' : dataItem.term}
          </div>
        )
      }

      const renderTermInput = () => {
        return (
          <div className="TermbasesTable__input-wrapper">
            <input
              className="TermbasesTable__input"
              value={editedTerm as string}
              onChange={changeEditedTerm}
              autoFocus
            />
            <SaveInput
              className="TermbasesTable__save"
              onClick={handleSaveEditedTerm}
            />
            <CancelEditing
              className="TermbasesTable__cancel"
              onClick={cancelEditing}
            />
          </div>
        )
      }

      return (
        <div
          key={index}
          className="TermbasesTable__term-outer-wrapper"
        >
          {isEditing ? renderTermInput() : renderTerm()}
        </div>
      )
    })

    const pronunciation = map(termbaseEntry.attributes.data, (dataItem, index) => {
      const isEditing = typeof(editedPronunciation) === 'string' && editedTermbaseEntryId === termbaseEntry.id && editedTermbaseEntryDataItemIndex === index

      const startEditingPronunciation = () => {
        props.setEditedPronunciation(dataItem.pronunciation || '')
        props.setEditedTermbaseEntryId(termbaseEntry.id)
        props.setEditedTermbaseEntryDataItemIndex(index)
      }

      const renderPronunciation = () => {
        return (
          <div
            className={classnames(
              'TermbasesTable__pronunciation-inner-wrapper', {
                'TermbasesTable__pronunciation-inner-wrapper-empty': !dataItem.pronunciation || dataItem.pronunciation.length === 0
              }
            )}
            onClick={startEditingPronunciation}
          >
            {dataItem.pronunciation}
          </div>
        )
      }

      const renderPronunciationInput = () => {
        return (
          <div className="TermbasesTable__input-wrapper">
            <input
              className="TermbasesTable__input"
              value={editedPronunciation as string}
              onChange={changeEditedPronunciation}
              autoFocus
            />
            <SaveInput
              className="TermbasesTable__save"
              onClick={handleSaveEditedPronunciation}
            />
            <CancelEditing
              className="TermbasesTable__cancel"
              onClick={cancelEditing}
            />
          </div>
        )
      }

      return (
        <div
          key={index}
          className="TermbasesTable__pronunciation-outer-wrapper"
        >
          {isEditing ? renderPronunciationInput() : renderPronunciation()}
        </div>
      )
    })

    const updatedAt = map(termbaseEntry.attributes.data, (dataItem, index) => {
      return (
        <div
          key={index}
          className="TermbasesTable__updated-at-wrapper"
        >
          {dataItem.updatedAt}
        </div>
      )
    })

    const handleDeleteTermbaseEntry = () => {
      props.deleteTermbaseEntry(termbaseEntry.id)
    }

    return (
      <tr
        key={termbaseEntry.id}
        className="TermbasesTable__body-row"
      >
        <td className="TermbasesTable__language">
          {language}
        </td>
        <td className="TermbasesTable__term">
          {term}
        </td>
        <td className="TermbasesTable__pronunciation">
          {pronunciation}
        </td>
        <td className="TermbasesTable__updated-at">
          {updatedAt}
        </td>
        <td className="TermbasesTable__delete">
          <Delete
            onClick={handleDeleteTermbaseEntry}
          />
        </td>
      </tr>
    )
  })

  return (
    <table className="TermbasesTable">
      {renderTableHead()}
      {renderTableBody()}
    </table>
  )
}

export default TermbasesTable
