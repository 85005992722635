import reduce from 'lodash/reduce'
import indexOf from 'lodash/indexOf'

export interface IProcessingFactors {
  [key: string]: number
}

const PROCESSING_FACTORS: IProcessingFactors = {
  initializing: 0.01, // No data
  downloading: 0.18,
  transcribing: 0.79,
  importingSrt: 0.01,
  waitingDiarizationWithTranscription: 0.12, // 0.91 for generatingDiarization - 0.79 for transcribing since done in parallel
  waitingDiarizationWithoutTranscription: 0.91, // not done in parallel when an SRT is imported
  detectingGender: 0.15, // Very rough estimate because of lack of data. Currently only used for external api, so not relevant for the processing page
  checkingQuality: 0.57, // Estimated to be similar to adaptingTranslation
  translating: 0.02, // No data
  translatingAndCheckingQuality: 0.59, // Difference to translating estimated to be similar to adaptingTranslation
  separatingAudio: 0.44,
  initializingVoice: 0.01, // No data
  generatingVoice: 0.21,
  adaptingTranslation: 0.57,
  regeneratingVoice: 0.21,
  lipSyncing: 22.11,
  processed: 0.00
}

class ProcessingInfo {
  static getTotalProcessingDuration = (allProcessingSteps: string[], projectVersionDuration: number) => {

    const sumOfFactors = reduce(allProcessingSteps, (sum, processingStep) => {
      return sum + PROCESSING_FACTORS[processingStep] || 0
    }, 0)

    return sumOfFactors * projectVersionDuration
  }

  static getPercentageLimitsForStep = (currentProcessingStep: string, allProcessingSteps: string[], totalProcessingDuration: number, projectVersionDuration: number) => {
    const currentProcessingStepIndex = indexOf(allProcessingSteps, currentProcessingStep)
    const remainingProcessingSteps = allProcessingSteps.slice(currentProcessingStepIndex)

    const sumOfFactors = reduce(remainingProcessingSteps, (sum, processingStep) => {
      return sum + PROCESSING_FACTORS[processingStep] || 0
    }, 0)

    const lowerLimit = 100 - sumOfFactors * projectVersionDuration / totalProcessingDuration * 100
    const upperLimit = 100 - (sumOfFactors - (PROCESSING_FACTORS[currentProcessingStep] || 0)) * projectVersionDuration / totalProcessingDuration * 100

    return [lowerLimit, upperLimit]
  }
}

export default ProcessingInfo
