import React, { useState, useEffect } from 'react'
import find from 'lodash/find'
import TermbasesFormBody from './TermbasesFormBody'

import {
  ITermbase,
  ITermbaseEntry,
  ISetActiveTermbaseIdPayload,
  ISetActivePagePayload,
  ISetSearchTermPayload,
  ICreateTermbasePayload,
  IUpdateTermbasePayload,
  IDeleteTermbasePayload,
  IUpdateTermbaseEntryPayload,
  IAddTermbaseEntryPayload,
  IDeleteTermbaseEntryPayload,
} from '@SiteModules/Termbase/types'

interface TermbasesFormProps {
  readonly termbases: ITermbase[]
  readonly termbaseEntries: ITermbaseEntry[]
  readonly activeTermbaseId?: number
  readonly loadingTermbases: boolean
  readonly loadingTermbaseEntries: boolean
  readonly modifying: boolean
  readonly activePage: number
  readonly totalTermbaseEntryCount: number
  readonly totalPages: number
  readonly searchTerm?: string
  readonly allFullnameLanguages: string[]
  createTermbase(payload: ICreateTermbasePayload): void
  updateTermbase(payload: IUpdateTermbasePayload): void
  deleteTermbase(payload: IDeleteTermbasePayload): void
  updateTermbaseEntry(payload: IUpdateTermbaseEntryPayload): void
  addTermbaseEntry(payload: IAddTermbaseEntryPayload): void
  deleteTermbaseEntry(payload: IDeleteTermbaseEntryPayload): void
  setActiveTermbaseId(payload: ISetActiveTermbaseIdPayload): void
  setActivePage(payload: ISetActivePagePayload): void
  setSearchTerm(payload: ISetSearchTermPayload): void
}

const TermbasesForm: React.FC<TermbasesFormProps> = props => {
  const {
    termbases,
    termbaseEntries,
    activeTermbaseId,
    loadingTermbases,
    loadingTermbaseEntries,
    modifying,
    activePage,
    totalTermbaseEntryCount,
    totalPages,
    searchTerm,
    allFullnameLanguages,
  } = props

  const [delayedActionType, setDelayedActionType] = useState('')
  const [delayedActionItem, setDelayedActionItem] = useState<null | number>(null)
  const [editedTerm, setEditedTerm] = useState<null | string>(null)
  const [editedTermbaseEntryId, setEditedTermbaseEntryId] = useState<null | number>(null)
  const [editedTermbaseEntryDataItemIndex, setEditedTermbaseEntryDataItemIndex] = useState<null | number>(null)
  const [editedPronunciation, setEditedPronunciation] = useState<null | string>(null)
  const [hasCreatedTermbase, setHasCreatedTermbase] = useState(false)
  const [keepAfterLoading, setKeepAfterLoading] = useState<null | string>(null)

  const activeTermbase = find(termbases, termbase => termbase.id === activeTermbaseId)
  const isBusy = loadingTermbases || loadingTermbaseEntries || modifying
  const hasTermbases = termbases.length > 0

  useEffect(() => {
    if (!loadingTermbases) {
      if (hasCreatedTermbase) {
        handleCreating()
      } else {
        handleLoadingUpdatingDeleting()
      }
    }
  }, [loadingTermbases])

  useEffect(() => {
    if (!loadingTermbaseEntries) {
      if (keepAfterLoading !== 'term') {
        setEditedTerm(null)
      }

      if (keepAfterLoading !== 'pronunciation') {
        setEditedPronunciation(null)
      }

      if (keepAfterLoading) {
        setKeepAfterLoading(null)
      } else {
        setEditedTermbaseEntryId(null)
        setEditedTermbaseEntryDataItemIndex(null)
      }
    }
  }, [loadingTermbaseEntries])

  const handleCreating = () => {
    const createdTermbase = termbases.reduce((a, b) => {
      return (a.id > b.id) ? a : b
    })
    props.setActiveTermbaseId({ activeTermbaseId: createdTermbase.id })
    setHasCreatedTermbase(false)
  }

  const handleLoadingUpdatingDeleting = () => {
    if (!activeTermbase) {
      const newActiveTermbase = hasTermbases ? termbases[0] : undefined
      props.setActiveTermbaseId({ activeTermbaseId: newActiveTermbase?.id })
    } else {
      const updatedActiveTermbase = find(termbases, termbase => termbase.id === activeTermbase.id)
      const newActiveTermbase = updatedActiveTermbase || (hasTermbases ? termbases[0] : undefined)
      props.setActiveTermbaseId({ activeTermbaseId: newActiveTermbase?.id })
    }
  }

  const delayedAction = () => {
    if (delayedActionType === 'deleteTermbase' && activeTermbase) {
      props.deleteTermbase({ id: activeTermbase.id })
    }

    if (delayedActionType === 'deleteTermbaseEntry' && delayedActionItem) {
      props.deleteTermbaseEntry({ id: delayedActionItem })
    }
  }

  const handleCreate = (payload: ICreateTermbasePayload) => {
    props.createTermbase(payload)

    setHasCreatedTermbase(true)
  }

  return (
    <TermbasesFormBody
      termbases={termbases}
      termbaseEntries={termbaseEntries}
      activeTermbase={activeTermbase}
      editedTerm={editedTerm}
      editedPronunciation={editedPronunciation}
      editedTermbaseEntryId={editedTermbaseEntryId}
      editedTermbaseEntryDataItemIndex={editedTermbaseEntryDataItemIndex}
      setDelayedActionType={setDelayedActionType}
      setDelayedActionItem={setDelayedActionItem}
      setActiveTermbaseId={props.setActiveTermbaseId}
      setActivePage={props.setActivePage}
      setEditedTerm={setEditedTerm}
      setEditedPronunciation={setEditedPronunciation}
      setEditedTermbaseEntryId={setEditedTermbaseEntryId}
      setEditedTermbaseEntryDataItemIndex={setEditedTermbaseEntryDataItemIndex}
      isBusy={isBusy}
      createTermbase={handleCreate}
      updateTermbase={props.updateTermbase}
      delayedAction={delayedAction}
      updateTermbaseEntry={props.updateTermbaseEntry}
      addTermbaseEntry={props.addTermbaseEntry}
      activePage={activePage}
      totalTermbaseEntryCount={totalTermbaseEntryCount}
      totalPages={totalPages}
      searchTerm={searchTerm}
      setSearchTerm={props.setSearchTerm}
      allFullnameLanguages={allFullnameLanguages}
      setKeepAfterLoading={setKeepAfterLoading}
    />
  )
}

export default TermbasesForm
