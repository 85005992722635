import without from 'lodash/without'
import smartMerge from '@Utils/SmartMerge'

import {
  IProjectVersion,
  ISubtitle,
  ProjectVersionActionTypes,
  IProjectVersionsListState,
  IOpenExportationAction,
  IOpenRateSubtitle,
  IOpenExternalToken,
  IOpenImportSrtAction,
  TProjectVersionsListActionTypes,
  IFetchProjectVersionsSuccessAction,
  IUpdateProjectVersionsSuccessAction,
  IChangeSelectedProjectVersionsAction,
} from './types'

import {
  DeleteProjectVersionActionTypes
} from '@SiteContainers/DeleteProjectVersionPopup/types'

import {
  TProjectVersionSettingsAction,
  ProjectVersionSettingsActionTypes,
} from '@SitePages/ProjectVersionSettings/types'

import {
  LabelActionTypes,
} from '@SiteModules/Label/types'

import {
  TUserActions,
} from '@SiteModules/User/types'

import {
  ProjectActionTypes
} from '@SiteModules/Project/types'

import {
  ElementActionTypes,
  TElementAction,
  ISubmitUrlsSuccessAction,
  ICreateElementSuccessAction,
} from '@SiteModules/Element/types'

export const initialState: IProjectVersionsListState = {
  projectVersions: [],
  subtitles: [],
  loading: false,
  subtitleId: -1,
  subtitleIds: [],
  currentPage: 1,
  totalCount: 0,
  totalPages: 1,
  editingProjectVersionIds: [],
  selectedProjectVersions: [],
  openImportSrtPopup: false,
  subtitleHasSpeakers: false,
  openExportationPopup: false,
  openRateSubtitlePopup: false,
  openExternalTokenPopup: false,
  openDeleteProjectVersionPopup: false,
  editingVideoBelongsToManualProject: false,
}

type TAction = TProjectVersionsListActionTypes | TProjectVersionSettingsAction | TUserActions | TElementAction

export const projectVersionsListReducer = (
  state: IProjectVersionsListState = initialState,
  action: TAction,
): IProjectVersionsListState => {
  switch (action.type) {
    case ProjectVersionActionTypes.CLOSE_POPUP: return closePopup(state)
    case ProjectVersionActionTypes.LOAD_PROJECT_VERSIONS: return loadProjectVersions(state)
    case ProjectVersionActionTypes.FETCH_PROJECT_VERSIONS_SUCCESS: return fetchProjectVersionsSuccess(state, action)
    case ProjectVersionActionTypes.FETCH_PROJECT_VERSIONS_FAILURE: return projectVersionsListFailure(state)
    case ProjectVersionActionTypes.UPDATE_PROJECT_VERSIONS_SUCCESS: return updateProjectVersionsSuccess(state, action)
    case ProjectVersionActionTypes.UPDATE_PROJECT_VERSIONS_FAILURE: return projectVersionsListFailure(state)
    case ProjectVersionActionTypes.OPEN_IMPORT_SRT_POPUP: return openImportSrtPopup(state, action)
    case ProjectVersionActionTypes.OPEN_DELETE_PROJECT_VERSION_POPUP: return openDeleteProjectVersionPopup(state)
    case ProjectVersionActionTypes.OPEN_EXTERNAL_TOKEN_POPUP: return openExternalTokenPopup(state, action)
    case ProjectVersionActionTypes.OPEN_EXPORTATION_POPUP: return openExportationPopup(state, action)
    case ProjectVersionActionTypes.OPEN_RATE_SUBTITLE_POPUP: return openRateSubtitlePopup(state, action)
    case ProjectVersionActionTypes.CHANGE_SELECTED_PROJECT_VERSIONS: return changeSelectedProjectVersions(state, action)
    case ProjectVersionActionTypes.INCREASE_PAGE_COUNT: return increasePageCount(state)
    case ProjectVersionActionTypes.DECREASE_PAGE_COUNT: return decreasePageCount(state)
    case ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE: return resetProjectVersionSelectionAndPageCount(state)
    case DeleteProjectVersionActionTypes.DELETE_PROJECT_VERSION_SUCCESS: return resetProjectVersionSelectionAndPageCount(state)
    case LabelActionTypes.CHANGE_SELECTED_LABELS: return resetProjectVersionSelectionAndPageCount(state)
    case LabelActionTypes.RESET_LABEL_SELECTION: return resetProjectVersionSelectionAndPageCount(state)
    case ProjectActionTypes.CREATE_PROJECT: return resetProjectVersionSelectionAndPageCount(state)
    case ElementActionTypes.CREATE_ELEMENT_SUCCESS: return updateProjectVersionsList(state, action)
    case ElementActionTypes.SUBMIT_URLS_SUCCESS: return updateProjectVersionsList(state, action)

    default: return state
  }
}

const loadProjectVersions = (state: IProjectVersionsListState) => {
  return {
    ...state,
    loading: true
  }
}

function isIOpenExternalToken(arg: any): arg is IOpenExternalToken {
  return arg.payload && arg.payload.subtitleId != null
}

const openExternalTokenPopup = (state: IProjectVersionsListState, action: TAction): IProjectVersionsListState => {
  let { subtitleId } = state

  if (isIOpenExternalToken(action)) {
    subtitleId = action.payload.subtitleId
  }

  return { ...state, subtitleId, openExternalTokenPopup: true }
}

function isIOpenExportation(arg: any): arg is IOpenExportationAction {
  return arg.payload != null && arg.payload.subtitleIds != null
}

const openExportationPopup = (state: IProjectVersionsListState, action: TAction): IProjectVersionsListState => {
  let subtitleIds: number[] = []

  if (isIOpenExportation(action)) {
    subtitleIds = subtitleIds.concat(action.payload.subtitleIds)
  }

  return {
    ...state,
    subtitleIds,
    openExportationPopup: true
  }
}

function isIOpenRateSubtitle(arg: any): arg is IOpenRateSubtitle {
  return arg.payload && arg.payload.subtitleId != null
}

const openRateSubtitlePopup = (state: IProjectVersionsListState, action: TAction): IProjectVersionsListState => {
  let subtitleId = -1

  if (isIOpenRateSubtitle(action)) {
    subtitleId = action.payload.subtitleId
  }

  return { ...state, subtitleId, openRateSubtitlePopup: true }
}

function isIOpenImportSrtPopup(arg: any): arg is IOpenImportSrtAction {
  return arg.payload && arg.payload.subtitleId != null && arg.payload.projectVersionId != null
}

const openImportSrtPopup = (state: IProjectVersionsListState, action: TAction): IProjectVersionsListState => {
  let subtitleId = -1
  let editingProjectVersionIds: string[] = []

  if (isIOpenImportSrtPopup(action)) {
    subtitleId = action.payload.subtitleId
    editingProjectVersionIds = [action.payload.projectVersionId]
  }

  return { ...state, editingProjectVersionIds, subtitleId, openImportSrtPopup: true }
}

const openDeleteProjectVersionPopup = (state: IProjectVersionsListState): IProjectVersionsListState => {
  return { ...state, openDeleteProjectVersionPopup: true }
}

const fetchProjectVersionsSuccess = (state: IProjectVersionsListState, action: TAction): IProjectVersionsListState => {
  let projectVersions: IProjectVersion[] = []
  let subtitles: ISubtitle[] = []
  let totalCount: number = 0
  let totalPages: number = 1

  if (isIFetchProjectVersionsSuccessAction(action)) {
    projectVersions = action.projectVersions

    subtitles = action.subtitles
    totalCount = action.totalCount
    totalPages = action.totalPages
  }

  return { ...state, projectVersions, subtitles, totalCount, totalPages, loading: false }
}

function isIFetchProjectVersionsSuccessAction(arg: any): arg is IFetchProjectVersionsSuccessAction {
  return arg.projectVersions != null
}

const projectVersionsListFailure = (state: IProjectVersionsListState): IProjectVersionsListState => {
  return { ...state, loading: false }
}

function isIUpdateProjectVersionsSuccessAction(arg: any): arg is IUpdateProjectVersionsSuccessAction {
  return arg.payload.projectVersions.data != null && arg.payload.subtitles.data != null
}

const updateProjectVersionsSuccess = (state: IProjectVersionsListState, action: TAction): IProjectVersionsListState => {
  let projectVersions: IProjectVersion[] = []
  let subtitles: ISubtitle[] = []

  if (isIUpdateProjectVersionsSuccessAction(action)) {
    const projectVersionsToUpdate = action.payload.projectVersions.data
    const subtitlesToUpdate = action.payload.subtitles.data

    projectVersions = smartMerge(state.projectVersions, projectVersionsToUpdate)
    subtitles = smartMerge(state.subtitles, subtitlesToUpdate)
  }

  return { ...state, projectVersions, subtitles, loading: false }
}

const closePopup = (state: IProjectVersionsListState): IProjectVersionsListState => {
  return {
    ...state,
    subtitleId: -1,
    openImportSrtPopup: false,
    openExportationPopup: false,
    openRateSubtitlePopup: false,
    openExternalTokenPopup: false,
    openDeleteProjectVersionPopup: false,
    editingProjectVersionIds: []
  }
}

function isIChangeSelectedProjectVersions(arg: any): arg is IChangeSelectedProjectVersionsAction {
  return arg.payload.projectVersionId != null && arg.payload.selectProjectVersion != null
}

const changeSelectedProjectVersions = (state:  IProjectVersionsListState, action: TAction):  IProjectVersionsListState => {
  let selectedProjectVersions = state.selectedProjectVersions

  if (isIChangeSelectedProjectVersions(action)){
    const { selectProjectVersion, projectVersionId } = action.payload

    selectedProjectVersions = selectProjectVersion ? selectedProjectVersions.concat([projectVersionId]) : without(selectedProjectVersions, projectVersionId)
  }

  return { ...state, selectedProjectVersions }
}

const resetProjectVersionSelectionAndPageCount = (state:  IProjectVersionsListState): IProjectVersionsListState => {
  const selectedProjectVersions: string[] = []

  return { ...state, selectedProjectVersions, currentPage: 1 }
}

const increasePageCount = (state: IProjectVersionsListState): IProjectVersionsListState => {
  return { ...state, currentPage: state.currentPage + 1, selectedProjectVersions: [] }
}

const decreasePageCount = (state: IProjectVersionsListState): IProjectVersionsListState => {
  return { ...state, currentPage: state.currentPage - 1, selectedProjectVersions: [] }
}

function isICreateElementSuccessAction(arg: any): arg is ICreateElementSuccessAction {
  return arg.payload.data != null
}

function isISubmitUrlsSuccessAction(arg: any): arg is ISubmitUrlsSuccessAction {
  return arg.payload.data != null
}

const updateProjectVersionsList = (state: IProjectVersionsListState, action: TAction): IProjectVersionsListState => {
  let projectVersions: IProjectVersion[] = []

  if (isICreateElementSuccessAction(action) || isISubmitUrlsSuccessAction(action)) {
    const newProjectVersions = action.payload.data

    projectVersions = [...newProjectVersions, ...state.projectVersions]
  }

  return { ...state, projectVersions }
}
