import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '../../rootReducer'
import Header from '@SiteContainers/Header'
import GuidelinesForm from '@SiteComponents/GuidelinesForm'

import {
  IGuideline,
  ICreateGuidelinePayload,
  IUpdateGuidelinePayload,
  IDeleteGuidelinePayload,
  IUpdateDefaultGuidelinePayload,
  IDeleteDefaultGuidelinePayload,
} from '@SiteModules/Guideline/types'

import {
  fetchGuidelines,
  createGuideline,
  updateGuideline,
  deleteGuideline,
  updateDefaultGuideline,
  deleteDefaultGuideline,
} from '@SiteModules/Guideline/actions'

import {
  getCaptionGuidelines,
  getTranslationGuidelines,
} from '@SiteModules/Guideline/selectors'

import './Guidelines.scss'

interface GuidelinesProps {
  readonly captionGuidelines: IGuideline[]
  readonly translationGuidelines: IGuideline[]
  readonly loading: boolean
  readonly modifying: boolean
  fetchGuidelines(): void
  createGuideline(payload: ICreateGuidelinePayload): void
  updateGuideline(payload: IUpdateGuidelinePayload): void
  deleteGuideline(payload: IDeleteGuidelinePayload): void
  updateDefaultGuideline(payload: IUpdateDefaultGuidelinePayload): void
  deleteDefaultGuideline(payload: IDeleteDefaultGuidelinePayload): void
}

interface GuidelinesState {
  hasLoadedInitially: boolean
}

class Guidelines extends React.Component<GuidelinesProps, GuidelinesState> {
  constructor(props: GuidelinesProps) {
    super(props)

    this.state = {
      hasLoadedInitially: false
    }
  }

  componentDidMount() {
    this.props.fetchGuidelines()
  }

  componentDidUpdate(prevProps: GuidelinesProps) {
    if (!this.state.hasLoadedInitially && prevProps.loading !== this.props.loading && !this.props.loading) {
      this.setState({ hasLoadedInitially: true })
    }
  }

  render() {
    const {
      captionGuidelines,
      translationGuidelines,
      loading,
      modifying,
    } = this.props

    return (
      <div className="Guidelines">
        <Header />
        <div className="Guidelines__title">
          Guidelines
        </div>
        <div className="Guidelines__subtitle">
          Improve and adapt your subtitles according to your needs.
        </div>
        {this.state.hasLoadedInitially && (
          <GuidelinesForm
            captionGuidelines={captionGuidelines}
            translationGuidelines={translationGuidelines}
            loading={loading}
            modifying={modifying}
            createGuideline={this.props.createGuideline}
            updateGuideline={this.props.updateGuideline}
            deleteGuideline={this.props.deleteGuideline}
            updateDefaultGuideline={this.props.updateDefaultGuideline}
            deleteDefaultGuideline={this.props.deleteDefaultGuideline}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { guidelines } = state

  return {
    captionGuidelines: getCaptionGuidelines(guidelines),
    translationGuidelines: getTranslationGuidelines(guidelines),
    loading: guidelines.loading,
    modifying: guidelines.modifying,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    fetchGuidelines,
    createGuideline,
    updateGuideline,
    deleteGuideline,
    updateDefaultGuideline,
    deleteDefaultGuideline,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Guidelines)
