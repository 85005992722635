import React from 'react'
import map from 'lodash/map'
import DropdownV2 from '@EditorComponents/DropdownV2'

import './Termbase.scss'

interface TermbaseProps {
  readonly termbaseNames: string[]
  readonly selectedTermbaseName?: string
  setSelectedTermbaseName(newTermbaseName?: string): void
}

const NO_TERMBASE = 'None'

const Termbase: React.FC<TermbaseProps> = props => {
  const { termbaseNames, selectedTermbaseName } = props

  const termbasesPresent = termbaseNames.length > 0
  const termbaseNamesWithOptionForNoList = [NO_TERMBASE].concat(termbaseNames)

  const changeTermbaseName = (newTermbaseName: string) => {
    newTermbaseName === NO_TERMBASE ? props.setSelectedTermbaseName(undefined) : props.setSelectedTermbaseName(newTermbaseName)
  }

  const itemsToShow = map(termbaseNamesWithOptionForNoList, (termbaseName, index) => {
    return (
      <span className="Termbase__dropdown-item" key={index}>
        {termbaseName}
      </span>
    )
  })

  return (
    <div className="Termbase__value-wrapper">
      {termbasesPresent ? (
        <React.Fragment>
          <span className="Termbase__value">Term base:</span>
          <DropdownV2
            items={termbaseNamesWithOptionForNoList}
            itemsToShow={itemsToShow}
            currentItem={selectedTermbaseName || NO_TERMBASE}
            onChange={changeTermbaseName}
            noPortal
          />
        </React.Fragment>
      ) : (
        <div className="Termbase__create">
          <a href="/customizations/termbases">Create a term base</a>
        </div>
      )}

    </div>
  )
}

export default Termbase
