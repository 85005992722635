import {
  TermbaseActionTypes,
  IFetchTermbasesSuccessResponseBody,
  IFetchTermbaseEntriesPayload,
  IFetchTermbaseEntriesSuccessResponseBody,
  ISetActiveTermbaseIdPayload,
  ISetActivePagePayload,
  ISetSearchTermPayload,
  ICreateTermbasePayload,
  IUpdateTermbasePayload,
  IDeleteTermbasePayload,
  IUpdateTermbaseEntryPayload,
  IAddTermbaseEntryPayload,
  IDeleteTermbaseEntryPayload,
} from './types'

export const fetchTermbases = () => ({
  type: TermbaseActionTypes.FETCH_TERMBASES,
})

export const fetchTermbasesFailure = () => ({
  type: TermbaseActionTypes.FETCH_TERMBASES_FAILURE,
})

export const fetchTermbasesSuccess = (payload: IFetchTermbasesSuccessResponseBody) => ({
  type: TermbaseActionTypes.FETCH_TERMBASES_SUCCESS,
  payload,
})

export const fetchTermbaseEntries = (payload: IFetchTermbaseEntriesPayload) => ({
  type: TermbaseActionTypes.FETCH_TERMBASE_ENTRIES,
  payload,
})

export const fetchTermbaseEntriesFailure = () => ({
  type: TermbaseActionTypes.FETCH_TERMBASE_ENTRIES_FAILURE,
})

export const fetchTermbaseEntriesSuccess = (payload: IFetchTermbaseEntriesSuccessResponseBody) => ({
  type: TermbaseActionTypes.FETCH_TERMBASE_ENTRIES_SUCCESS,
  payload,
})

export const setActiveTermbaseId = (payload: ISetActiveTermbaseIdPayload) => ({
  type: TermbaseActionTypes.SET_ACTIVE_TERMBASE_ID,
  payload,
})

export const setActivePage = (payload: ISetActivePagePayload) => ({
  type: TermbaseActionTypes.SET_ACTIVE_PAGE,
  payload,
})

export const resetTermbaseEntries = () =>  ({
  type: TermbaseActionTypes.RESET_TERMBASE_ENTRIES,
})

export const setSearchTerm = (payload: ISetSearchTermPayload) =>  ({
  type: TermbaseActionTypes.SET_SEARCH_TERM,
  payload
})

export const createTermbase = (payload: ICreateTermbasePayload) => ({
  type: TermbaseActionTypes.CREATE_TERMBASE,
  payload,
})

export const createTermbaseFailure = () => ({
  type: TermbaseActionTypes.CREATE_TERMBASE_FAILURE,
})

export const createTermbaseSuccess = () => ({
  type: TermbaseActionTypes.CREATE_TERMBASE_SUCCESS,
})

export const updateTermbase = (payload: IUpdateTermbasePayload) => ({
  type: TermbaseActionTypes.UPDATE_TERMBASE,
  payload,
})

export const updateTermbaseFailure = () => ({
  type: TermbaseActionTypes.UPDATE_TERMBASE_FAILURE,
})

export const updateTermbaseSuccess = () => ({
  type: TermbaseActionTypes.UPDATE_TERMBASE_SUCCESS,
})

export const updateTermbaseEntry = (payload: IUpdateTermbaseEntryPayload) => ({
  type: TermbaseActionTypes.UPDATE_TERMBASE_ENTRY,
  payload,
})

export const updateTermbaseEntryFailure = () => ({
  type: TermbaseActionTypes.UPDATE_TERMBASE_ENTRY_FAILURE,
})

export const updateTermbaseEntrySuccess = () => ({
  type: TermbaseActionTypes.UPDATE_TERMBASE_ENTRY_SUCCESS,
})

export const deleteTermbase = (payload: IDeleteTermbasePayload) => ({
  type: TermbaseActionTypes.DELETE_TERMBASE,
  payload,
})

export const deleteTermbaseFailure = () => ({
  type: TermbaseActionTypes.DELETE_TERMBASE_FAILURE,
})

export const deleteTermbaseSuccess = () => ({
  type: TermbaseActionTypes.DELETE_TERMBASE_SUCCESS,
})

export const deleteTermbaseEntry = (payload: IDeleteTermbaseEntryPayload) => ({
  type: TermbaseActionTypes.DELETE_TERMBASE_ENTRY,
  payload,
})

export const deleteTermbaseEntryFailure = () => ({
  type: TermbaseActionTypes.DELETE_TERMBASE_ENTRY_FAILURE,
})

export const deleteTermbaseEntrySuccess = () => ({
  type: TermbaseActionTypes.DELETE_TERMBASE_ENTRY_SUCCESS,
})

export const addTermbaseEntry = (payload: IAddTermbaseEntryPayload) => ({
  type: TermbaseActionTypes.ADD_TERMBASE_ENTRY,
  payload,
})

export const addTermbaseEntrySuccess = () => ({
  type: TermbaseActionTypes.ADD_TERMBASE_ENTRY_SUCCESS,
})

export const addTermbaseEntryFailure = () => ({
  type: TermbaseActionTypes.ADD_TERMBASE_ENTRY_FAILURE,
})
