import React from 'react'
import InputForFloatAsText from '@EditorComponents/InputForFloatAsText'
import CheckboxV2 from '@EditorComponents/CheckboxV2'

interface ParameterProps {
  readonly title: string
  readonly value: number
  readonly minValue: number
  readonly maxValue: number
  readonly useValue: boolean
  readonly unit?: string
  changeParameter(newValue: number): void
  changeUseParameter(newUseParameter: boolean): void
}

const Parameter: React.FC<ParameterProps> = props => {
  const {
    title,
    value,
    minValue,
    maxValue,
    unit,
    useValue,
  } = props

  return (
    <div className={`CustomizationForm__parameter CustomizationForm__parameter-${unit ? unit.toLowerCase() : ''}`}>
      <span>
        <CheckboxV2
          checked={useValue}
          onChange={props.changeUseParameter}
        />
        <span className="CustomizationForm__parameter-value">{title}</span>
      </span>
      <InputForFloatAsText
        min={minValue}
        max={maxValue}
        defaultValue={value}
        numberOfDigits={0}
        value={value}
        step={1}
        unit={unit}
        onChange={props.changeParameter}
        tooltip={`Enter a number between ${minValue} and ${maxValue}`}
        disabled={!useValue}
        isForSite
      />
    </div>
  )
}

export default Parameter
