import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '../../rootReducer'
import Header from '@SiteContainers/Header'
import TermbasesForm from '@SiteComponents/TermbasesForm'

import {
  ITermbase,
  ITermbaseEntry,
  IFetchTermbaseEntriesPayload,
  ISetActiveTermbaseIdPayload,
  ISetActivePagePayload,
  ISetSearchTermPayload,
  ICreateTermbasePayload,
  IUpdateTermbasePayload,
  IDeleteTermbasePayload,
  IUpdateTermbaseEntryPayload,
  IAddTermbaseEntryPayload,
  IDeleteTermbaseEntryPayload,
} from '@SiteModules/Termbase/types'

import {
  fetchTermbases,
  fetchTermbaseEntries,
  setActiveTermbaseId,
  resetTermbaseEntries,
  setActivePage,
  setSearchTerm,
  createTermbase,
  updateTermbase,
  deleteTermbase,
  updateTermbaseEntry,
  addTermbaseEntry,
  deleteTermbaseEntry,
} from '@SiteModules/Termbase/actions'

import './Termbases.scss'

interface TermbasesProps {
  readonly termbases: ITermbase[]
  readonly termbaseEntries: ITermbaseEntry[]
  readonly activeTermbaseId?: number
  readonly activePage: number
  readonly totalPages: number
  readonly totalTermbaseEntryCount: number
  readonly searchTerm?: string
  readonly loadingTermbases: boolean
  readonly loadingTermbaseEntries: boolean
  readonly modifying: boolean
  readonly allFullnameLanguages: string[]
  fetchTermbases(): void
  fetchTermbaseEntries(payload: IFetchTermbaseEntriesPayload): void
  setActiveTermbaseId(payload: ISetActiveTermbaseIdPayload): void
  resetTermbaseEntries(): void
  setActivePage(payload: ISetActivePagePayload): void
  setSearchTerm(payload: ISetSearchTermPayload): void
  createTermbase(payload: ICreateTermbasePayload): void
  updateTermbase(payload: IUpdateTermbasePayload): void
  deleteTermbase(payload: IDeleteTermbasePayload): void
  updateTermbaseEntry(payload: IUpdateTermbaseEntryPayload): void
  addTermbaseEntry(payload: IAddTermbaseEntryPayload): void
  deleteTermbaseEntry(payload: IDeleteTermbaseEntryPayload): void
}

interface TermbasesState {
  hasLoadedInitially: boolean
}

const DEFAULT_PAGE = 1
const DEFAULT_SEARCH_TERM = ''

class Termbases extends React.Component<TermbasesProps, TermbasesState> {
  constructor(props: TermbasesProps) {
    super(props)

    this.state = {
      hasLoadedInitially: false
    }
  }

  componentDidMount() {
    this.props.fetchTermbases()
  }

  componentDidUpdate(prevProps: TermbasesProps) {
    if (!this.state.hasLoadedInitially && prevProps.loadingTermbases !== this.props.loadingTermbases && !this.props.loadingTermbases) {
      this.setState({ hasLoadedInitially: true })
    }
  }

  handleCreateTermbase = (payload: ICreateTermbasePayload) => {
    this.props.setSearchTerm({ searchTerm: DEFAULT_SEARCH_TERM })
    this.props.createTermbase(payload)
  }

  handleDeleteTermbase = (payload: IDeleteTermbasePayload) => {
    this.props.setSearchTerm({ searchTerm: DEFAULT_SEARCH_TERM })
    this.props.deleteTermbase(payload)
  }

  handleFetchTermbaseEntries = (termbaseId: number, page: number, searchTerm: string | undefined) => {
    const hasSearchTerm = searchTerm && searchTerm.length > 0
    this.props.fetchTermbaseEntries({ page, termbaseId, ...(hasSearchTerm ? { searchTerm } : {}) })
  }

  handleSetActiveTermbaseId = (payload: ISetActiveTermbaseIdPayload) => {
    this.props.setActivePage({ activePage: DEFAULT_PAGE })
    this.props.setActiveTermbaseId(payload)

    if (typeof(payload.activeTermbaseId) === 'number') {
      this.handleFetchTermbaseEntries(payload.activeTermbaseId, DEFAULT_PAGE, this.props.searchTerm)
    } else {
      this.props.resetTermbaseEntries()
    }
  }

  handleAddTermbaseEntry = (payload: IAddTermbaseEntryPayload) => {
    this.props.setActivePage({ activePage: DEFAULT_PAGE })
    this.props.setSearchTerm({ searchTerm: DEFAULT_SEARCH_TERM })
    this.props.addTermbaseEntry(payload)
  }

  handleDeleteTermbaseEntry = (payload: IDeleteTermbaseEntryPayload) => {
    const isOnLastPage = this.props.activePage === this.props.totalPages
    const hasOnlyOneItemOnThisPage = this.props.termbaseEntries.length === 1

    if (isOnLastPage && hasOnlyOneItemOnThisPage && this.props.activePage > 1) {
      this.props.setActivePage({ activePage: this.props.activePage - 1 })
    }

    this.props.deleteTermbaseEntry(payload)
  }

  handleSetActivePage = (payload: ISetActivePagePayload) => {
    this.props.setActivePage(payload)
    this.handleFetchTermbaseEntries(this.props.activeTermbaseId as number, payload.activePage, this.props.searchTerm)
  }

  handleSetSearchTerm = (payload: ISetSearchTermPayload) => {
    this.props.setActivePage({ activePage: DEFAULT_PAGE })
    this.props.setSearchTerm(payload)
    this.handleFetchTermbaseEntries(this.props.activeTermbaseId as number, DEFAULT_PAGE, payload.searchTerm)
  }

  render() {
    const {
      termbases,
      termbaseEntries,
      activeTermbaseId,
      loadingTermbases,
      loadingTermbaseEntries,
      modifying,
      activePage,
      totalTermbaseEntryCount,
      totalPages,
      searchTerm,
      allFullnameLanguages,
    } = this.props

    return (
      <div className="Termbases">
        <Header />
        <div className="Termbases__title">
          Term Bases
        </div>
        <div className="Termbases__subtitle">
          Improve your automatic translation thanks to consistency.
        </div>
        {this.state.hasLoadedInitially && (
          <TermbasesForm
            termbases={termbases}
            termbaseEntries={termbaseEntries}
            activeTermbaseId={activeTermbaseId}
            activePage={activePage}
            totalTermbaseEntryCount={totalTermbaseEntryCount}
            totalPages={totalPages}
            loadingTermbases={loadingTermbases}
            loadingTermbaseEntries={loadingTermbaseEntries}
            modifying={modifying}
            createTermbase={this.handleCreateTermbase}
            updateTermbase={this.props.updateTermbase}
            deleteTermbase={this.handleDeleteTermbase}
            updateTermbaseEntry={this.props.updateTermbaseEntry}
            addTermbaseEntry={this.handleAddTermbaseEntry}
            deleteTermbaseEntry={this.handleDeleteTermbaseEntry}
            setActiveTermbaseId={this.handleSetActiveTermbaseId}
            setActivePage={this.handleSetActivePage}
            searchTerm={searchTerm}
            setSearchTerm={this.handleSetSearchTerm}
            allFullnameLanguages={allFullnameLanguages}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { termbases } = state

  return {
    termbases: termbases.termbases,
    termbaseEntries: termbases.termbaseEntries,
    activeTermbaseId: termbases.activeTermbaseId,
    activePage: termbases.activePage,
    totalPages: termbases.totalPages,
    totalTermbaseEntryCount: termbases.totalTermbaseEntryCount,
    searchTerm: termbases.searchTerm,
    loadingTermbases: termbases.loadingTermbases,
    loadingTermbaseEntries: termbases.loadingTermbaseEntries,
    modifying: termbases.modifying,
    allFullnameLanguages: termbases.allFullnameLanguages,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    fetchTermbases,
    fetchTermbaseEntries,
    setActiveTermbaseId,
    resetTermbaseEntries,
    setActivePage,
    setSearchTerm,
    createTermbase,
    updateTermbase,
    deleteTermbase,
    updateTermbaseEntry,
    addTermbaseEntry,
    deleteTermbaseEntry,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Termbases)
