import React, { useState, useEffect } from 'react'
import uniq from 'lodash/uniq'
import classnames from 'classnames'
import Multiselect from '@checksub_team/react-widgets/lib/Multiselect'
import Popup from '@SiteComponents/Popup'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import TermbaseSettingsIcon from '@Images/termbase_settings.svg'

import {
  ITermbase,
  ISaveTermbasePayload,
} from '@SiteModules/Termbase/types'

import '@checksub_team/react-widgets/dist/css/react-widgets.css'
import '@Root/config/dropdowns.scss'
import './TermbaseSettingsPopup.scss'

interface TermbaseSettingsPopupProps {
  readonly allFullnameLanguages: string[]
  readonly termbaseToSetUp?: ITermbase
  readonly otherTermbasesNames: string[]
  closePopup(): void
  saveTermbase(payload: ISaveTermbasePayload): void
}

const TOP_LANGUAGES = [
  'Arabic',
  'Chinese, Mandarin (Simplified, China)',
  'English',
  'French',
  'French (Canada)',
  'German',
  'Hindi',
  'Italian',
  'Japanese',
  'Portuguese',
  'Russian',
  'Spanish'
]

const MAX_NAME_LENGTH = 100 // characters
const PROTECTED_NAME = 'None'

const TermbaseSettingsPopup: React.FC<TermbaseSettingsPopupProps> = props => {
  const {
    termbaseToSetUp,
    allFullnameLanguages,
    otherTermbasesNames,
  } = props

  const initialName = termbaseToSetUp ? termbaseToSetUp.attributes.name : ''
  const initialLanguages = termbaseToSetUp ? termbaseToSetUp.attributes.fullnameLanguages : []
  const [name, setName] = useState(initialName)
  const [languages, setLanguages] = useState(initialLanguages)

  const languagesWithTopLanguagesFirst = uniq(TOP_LANGUAGES.concat(allFullnameLanguages))
  const newName = name.trim()
  const inputUnchanged = newName === initialName && languages === initialLanguages
  const nameTooLong = newName.length > MAX_NAME_LENGTH
  const nameTooShort = newName.length === 0
  const nameAlreadyInUse = otherTermbasesNames.includes(newName)
  const nameProtected = newName === PROTECTED_NAME
  const noLanguagesSelected = languages.length === 0
  const inputCannotBeProcessed = noLanguagesSelected || nameTooShort || nameTooLong || nameAlreadyInUse || nameProtected
  const shouldDisableSaveButton = inputUnchanged || inputCannotBeProcessed

  const [showInfo, setShowInfo] = useState(false)
  const [infoTimeout, setInfoTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)
  const shouldShowInfo = showInfo && inputCannotBeProcessed

  let info = ''
  if (noLanguagesSelected) {
    info = 'The languages cannot be empty.'
  } else if (nameTooShort) {
    info = 'The name cannot be empty.'
  } else if (nameTooLong) {
    info = `The name cannot be longer than ${MAX_NAME_LENGTH} characters.`
  } else if (nameAlreadyInUse) {
    info = 'Name alread in use.'
  } else if (nameProtected) {
    info = 'Name cannot be used.'
  }

  useEffect(() => {
    const inputFieldLanguages = document.querySelectorAll('.TermbaseSettingsPopup__input-wrapper-multiselect .rw-multiselect .rw-input-reset')[0] as HTMLInputElement
    const inputContainerLanguages = document.querySelectorAll('.TermbaseSettingsPopup__input-wrapper-multiselect .rw-widget-input.rw-widget-picker.rw-widget-container')[0] as HTMLElement
    if (languages.length > 0) {
      inputFieldLanguages.placeholder = '+ Add language'
      inputContainerLanguages.style.border = 'none'
      inputContainerLanguages.style.paddingRight = '0px'
    } else {
      inputContainerLanguages.style.border = '1px solid #4D576D'

      const handleMouseEnter = () => {
        inputContainerLanguages.style.border = '1px solid #CA9FFC'
      }
      const handleMouseLeave = () => {
        inputContainerLanguages.style.border = '1px solid #4D576D'
      }

      inputContainerLanguages.addEventListener('mouseenter', handleMouseEnter)
      inputContainerLanguages.addEventListener('mouseleave', handleMouseLeave)

      return () => {
        inputContainerLanguages.removeEventListener('mouseenter', handleMouseEnter)
        inputContainerLanguages.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [languages])

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const handleSave = () => {
    props.saveTermbase({ name: newName, languages })

    props.closePopup()
  }

  const displayInfo = () => {
    if (infoTimeout) { return }

    const newInfoTimeout = setTimeout(() => {
      setShowInfo(true)
    }, window.Checksub.tooltipDelay)
    setInfoTimeout(newInfoTimeout)
  }

  const hideInfo = () => {
    if (!infoTimeout) { return }

    setShowInfo(false)
    clearTimeout(infoTimeout)
    setInfoTimeout(null)
  }

  const renderNameInput = () => {
    return (
      <div className="TermbaseSettingsPopup__input-wrapper">
        <label className="TermbaseSettingsPopup__input-label">
          Name:
        </label>
        <input
          className="TermbaseSettingsPopup__input"
          value={name}
          onChange={handleChangeName}
          placeholder="Enter name"
        />
      </div>
    )
  }

  const languageGroupHeading = ({ item }: {item: boolean}) => (
    item ? <span>Top Languages</span> : <span>All Languages</span>
  )

  const languageGrouping = (language: string) => TOP_LANGUAGES.includes(language)

  const onSearch = () => {
    setTimeout(() => {
      const emptyList = document.getElementsByClassName('rw-list-empty')

      if (emptyList && emptyList.length > 0) {
        emptyList[0].innerHTML = 'Not available'
      }
    }, 0)
  }

  const renderLanguageSelectionInput = () => {
    return (
      <div className={classnames('TermbaseSettingsPopup__input-wrapper TermbaseSettingsPopup__input-wrapper-multiselect', {'TermbaseSettingsPopup__input-wrapper-multiselect-filled-out': languages.length > 0})}>
        <label className="TermbaseSettingsPopup__input-label">
          Languages:
        </label>
        <Multiselect
          data={languagesWithTopLanguagesFirst}
          value={languages}
          onChange={setLanguages}
          groupComponent={languageGroupHeading}
          groupBy={languageGrouping}
          placeholder="Choose languages..."
          onSearch={onSearch}
        />
        <span className="TermbaseSettingsPopup__input-wrapper-multiselect-hide-border-patch"/>
      </div>
    )
  }

  return (
    <Popup
      className="TermbaseSettingsPopup"
      closePopup={props.closePopup}
    >
      <div className="TermbaseSettingsPopup__header">
        <TermbaseSettingsIcon />
        Term Base Settings
      </div>
      {renderNameInput()}
      {renderLanguageSelectionInput()}
      <div className="TermbaseSettingsPopup__button-wrapper">
        <ButtonNewV2
          styleType="neutral-secondary"
          size="small"
          caption="Cancel"
          onClick={props.closePopup}
        />
        <div
          onMouseEnter={displayInfo}
          onMouseLeave={hideInfo}
        >
          <ButtonNewV2
            styleType="brand-primary"
            size="small"
            caption="Save"
            onClick={handleSave}
            disabled={shouldDisableSaveButton}
          />
        </div>
        {shouldShowInfo && (
          <div className="TermbaseSettingsPopup__button-tooltip">
            {info}
          </div>
        )}
      </div>
    </Popup>
  )
}

export default TermbaseSettingsPopup
