import map from 'lodash/map'

import { createSelector } from 'reselect'

import {
  IVocabulary,
  IVocabularyState
} from './types'

export const getVocabularies = (state: IVocabularyState): IVocabulary[] => state.vocabularies

export const getVocabularyNames = createSelector(
  [getVocabularies],
  (vocabularies) => map(vocabularies, vocabulary => vocabulary.attributes.name)
)

export const getVocabularyIds = createSelector(
  [getVocabularies],
  (vocabularies) => map(vocabularies, vocabulary => vocabulary.id)
)
