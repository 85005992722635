import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '../../rootReducer'
import Header from '@SiteContainers/Header'
import VocabulariesForm from '@SiteComponents/VocabulariesForm'

import {
  IVocabulary,
  ICreateVocabularyPayload,
  IUpdateVocabularyPayload,
  IDeleteVocabularyPayload,
} from '@SiteModules/Vocabulary/types'

import {
  createVocabulary,
  updateVocabulary,
  deleteVocabulary,
  fetchVocabularies,
} from '@SiteModules/Vocabulary/actions'

import './Vocabularies.scss'

interface VocabulariesProps {
  readonly vocabularies: IVocabulary[]
  readonly loading: boolean
  readonly modifying: boolean
  fetchVocabularies(): void
  createVocabulary(payload: ICreateVocabularyPayload): void
  updateVocabulary(payload: IUpdateVocabularyPayload): void
  deleteVocabulary(payload: IDeleteVocabularyPayload): void
}

interface VocabulariesState {
  hasLoadedInitially: boolean
}

class Vocabularies extends React.Component<VocabulariesProps, VocabulariesState> {
  constructor(props: VocabulariesProps) {
    super(props)

    this.state = {
      hasLoadedInitially: false
    }
  }

  componentDidMount() {
    this.props.fetchVocabularies()
  }

  componentDidUpdate(prevProps: VocabulariesProps) {
    if (!this.state.hasLoadedInitially && prevProps.loading !== this.props.loading && !this.props.loading) {
      this.setState({ hasLoadedInitially: true })
    }
  }

  render() {
    const {
      vocabularies,
      loading,
      modifying,
    } = this.props

    return (
      <div className="Vocabularies">
        <Header />
        <div className="Vocabularies__title">
          Vocabularies
        </div>
        <div className="Vocabularies__subtitle">
          Add words to improve the results of your projects.
        </div>
        {this.state.hasLoadedInitially && (
          <VocabulariesForm
            vocabularies={vocabularies}
            loading={loading}
            modifying={modifying}
            createVocabulary={this.props.createVocabulary}
            updateVocabulary={this.props.updateVocabulary}
            deleteVocabulary={this.props.deleteVocabulary}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { vocabularies } = state

  return {
    vocabularies: vocabularies.vocabularies,
    loading: vocabularies.loading,
    modifying: vocabularies.modifying,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    fetchVocabularies,
    createVocabulary,
    updateVocabulary,
    deleteVocabulary,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Vocabularies)
