import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  IFetchTermbaseEntriesPayload,
  ICreateTermbasePayload,
  IUpdateTermbasePayload,
  IDeleteTermbasePayload,
  IUpdateTermbaseEntryPayload,
  IAddTermbaseEntryPayload,
  IDeleteTermbaseEntryPayload,
} from './types'

const config: AxiosRequestConfig = {
  headers: {
    'X-CSRF-Token': window.Checksub.token
  },
  responseType: 'json'
}

export function fetchTermbases() {
  return axios.get(`/api/v1/termbases/`)
}

export function fetchTermbaseEntries(payload: IFetchTermbaseEntriesPayload) {
  return axios.get('/api/v1/termbase_entries', { params: convertToSnakeCase(payload) })
}

export function createTermbase(payload: ICreateTermbasePayload) {
  return axios.post(`/api/v1/termbases/`, convertToSnakeCase(payload), config)
}

export function updateTermbase(payload: IUpdateTermbasePayload) {
  return axios.put(`/api/v1/termbases/${payload.id}`, convertToSnakeCase(payload), config)
}

export function deleteTermbase(payload: IDeleteTermbasePayload) {
  return axios.delete(`/api/v1/termbases/${payload.id}`, config)
}

export function updateTermbaseEntry(payload: IUpdateTermbaseEntryPayload) {
  return axios.put(`/api/v1/termbase_entries/${payload.termbaseEntryId}`, convertToSnakeCase(payload), config)
}

export function addTermbaseEntry(payload: IAddTermbaseEntryPayload) {
  return axios.put(`/api/v1/termbases/${payload.id}/add_entry`, config)
}

export function deleteTermbaseEntry(payload: IDeleteTermbaseEntryPayload) {
  return axios.delete(`/api/v1/termbase_entries/${payload.id}`, config)
}
