import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import LogoIcon from '@Images/logo.svg'
import ProfileButton from '@SiteComponents/ProfileButton'
import formattedCreditsWithHoursShort from '@Utils/FormattedCreditsWithHoursShort'

import {
  logout,
  createCustomerPortalSession,
} from '@SiteModules/User/actions'

import './Header.scss'

interface HeaderProps {
  readonly hasExternalChannels: boolean
  readonly subscriptionIsCurrent: boolean
  readonly subscriptionPlan: string
  readonly secondsAvailable: number
  readonly hasLoadedSubscriptionInfoSuccessfully: boolean
  readonly cancelAtPeriodEnd: boolean
  readonly renewalDate: string
  readonly usingPersonalSubscription: boolean
  readonly managedSsoOrganizationId: number | null
  readonly shouldNotLinkToDashboard?: boolean
  readonly shouldDisableProfileButton?: boolean
  readonly hideCredits?: boolean
  readonly hideSalesLink?: boolean
  createCustomerPortalSession(): void
  logout(payload: string): {}
}

interface HeaderState {
  readonly showInfoForCredits: boolean
  readonly infoForCreditsTimeout: ReturnType<typeof setTimeout> | null
}

class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props)

    this.state = {
      showInfoForCredits: false,
      infoForCreditsTimeout: null,
    }
  }

  toggleShowInfoForCredits = () => {
    if (this.state.infoForCreditsTimeout) {
      this.setState({ showInfoForCredits: false })
      clearTimeout(this.state.infoForCreditsTimeout)
      this.setState({ infoForCreditsTimeout: null })
    } else {
      const infoForCreditsTimeout = setTimeout(() => {
        this.setState({ showInfoForCredits: true })
      }, window.Checksub.tooltipDelay)
      this.setState({ infoForCreditsTimeout })
    }
  }

  render() {
    const {
      hasExternalChannels,
      subscriptionIsCurrent,
      secondsAvailable,
      hasLoadedSubscriptionInfoSuccessfully,
      cancelAtPeriodEnd,
      renewalDate,
      subscriptionPlan,
      shouldNotLinkToDashboard,
      shouldDisableProfileButton,
      usingPersonalSubscription,
      managedSsoOrganizationId,
      hideCredits,
      hideSalesLink,
    } = this.props

    const isAuthorized = window.Checksub.cookies.get('authorized')
    const shouldRenderSubscriptionCredits = hasLoadedSubscriptionInfoSuccessfully && subscriptionIsCurrent
    const shouldShowInfoForCredits = this.state.showInfoForCredits && !cancelAtPeriodEnd

    const renderSubscriptionCredits = () => {
      return (
        <div className={classnames(
          'Header__credits-wrapper',
          { 'Header__credits-wrapper-hidden': !shouldRenderSubscriptionCredits }
        )}>
          <div
            className="Header__credits"
            onMouseEnter={this.toggleShowInfoForCredits}
            onMouseLeave={this.toggleShowInfoForCredits}
          >
            Credits : {formattedCreditsWithHoursShort(secondsAvailable)}
          </div>
          {shouldShowInfoForCredits && (
            <div className="Header__tooltip">
              Plan will be renewed on {renewalDate}
            </div>
          )}
        </div>
      )
    }

    return (
      <header className="Header">
        {shouldNotLinkToDashboard ? (
          <LogoIcon className="Header__logo" />
        ) : (
          <Link to="/">
            <LogoIcon className="Header__logo" />
          </Link>
        )}

        {!hideSalesLink && (
          <a
            href="https://calendly.com/meetflorian/enterprise-demo"
            className="Header__contact-sales"
            target="_blank"
          >
            Contact Sales
          </a>
        )}

        <div className="Header__right">
          {!hideCredits && renderSubscriptionCredits()}
          {isAuthorized && (
            <ProfileButton
              hasExternalChannels={hasExternalChannels}
              subscriptionIsCurrent={subscriptionIsCurrent}
              subscriptionPlan={subscriptionPlan}
              createCustomerPortalSession={this.props.createCustomerPortalSession}
              logout={this.props.logout}
              disabled={shouldDisableProfileButton}
              usingPersonalSubscription={usingPersonalSubscription}
              managedSsoOrganizationId={managedSsoOrganizationId}
            />
          )}
        </div>
      </header>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { user, channelsTable, subscription } = state

  return {
    hasExternalChannels: channelsTable.externalChannels.length > 0,
    subscriptionIsCurrent: user.subscription.attributes.isCurrent,
    subscriptionPlan: user.subscription.attributes.plan,
    secondsAvailable: user.subscription.attributes.secondsAvailable,
    hasLoadedSubscriptionInfoSuccessfully: subscription.hasLoadedSuccessfully,
    cancelAtPeriodEnd: user.subscription.attributes.cancelAtPeriodEnd,
    renewalDate: user.subscription.attributes.renewalDate,
    usingPersonalSubscription: user.user.attributes.usingPersonalSubscription,
    managedSsoOrganizationId: user.user.attributes.managedSsoOrganizationId,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    logout,
    createCustomerPortalSession,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
