import { put, takeEvery, select } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'
import 'toastr/build/toastr.css'

import Logger from '@Utils/Logger'
import { IAction, ISuccessResponse } from '@Root/types'

import {
  fetchTermbases,
  fetchTermbasesSuccess,
  fetchTermbasesFailure,
  fetchTermbaseEntries,
  fetchTermbaseEntriesSuccess,
  fetchTermbaseEntriesFailure,
  createTermbaseSuccess,
  createTermbaseFailure,
  updateTermbaseSuccess,
  updateTermbaseFailure,
  deleteTermbaseSuccess,
  deleteTermbaseFailure,
  updateTermbaseEntrySuccess,
  updateTermbaseEntryFailure,
  addTermbaseEntrySuccess,
  addTermbaseEntryFailure,
  deleteTermbaseEntrySuccess,
  deleteTermbaseEntryFailure,
} from './actions'

import {
  TermbaseActionTypes,
  IFetchTermbasesSuccessResponseBody,
  IFetchTermbaseEntriesSuccessResponseBody,
  IFetchTermbaseEntriesPayload,
  ICreateTermbasePayload,
  IUpdateTermbasePayload,
  IDeleteTermbasePayload,
  IUpdateTermbaseEntryPayload,
  IDeleteTermbaseEntryPayload,
} from './types'

import * as API from './api'

const call: any = Eff.call

function* fetchTermbasesSaga() {
  try {
    const response: ISuccessResponse<IFetchTermbasesSuccessResponseBody> = yield call(API.fetchTermbases)
    const payloadResponse = response.data
    yield put(fetchTermbasesSuccess(payloadResponse))
  } catch (e) {
    Logger.error(e, 'Term bases could not be loaded')
    yield put(fetchTermbasesFailure())
  }
}

function* fetchTermbaseEntriesSaga(action: IAction<IFetchTermbaseEntriesPayload>) {
  try {
    const { payload } = action
    const response: ISuccessResponse<IFetchTermbaseEntriesSuccessResponseBody> = yield call(API.fetchTermbaseEntries, payload)
    const payloadResponse = response.data
    yield put(fetchTermbaseEntriesSuccess(payloadResponse))
  } catch (e) {
    Logger.error(e, 'Terms could not be loaded')
    yield put(fetchTermbaseEntriesFailure())
  }
}

function* createTermbaseSaga(action: IAction<ICreateTermbasePayload>) {
  try {
    const { payload } = action
    yield call(API.createTermbase, payload)
    yield put(createTermbaseSuccess())
    toastr.success('', 'Term base created')
    yield put(fetchTermbases())
  } catch (e) {
    Logger.error(e, 'Term base could not be created')
    yield put(createTermbaseFailure())
  }
}

function* updateTermbaseSaga(action: IAction<IUpdateTermbasePayload>) {
  try {
    const { payload } = action
    yield call(API.updateTermbase, payload)
    yield put(updateTermbaseSuccess())
    toastr.success('', 'Changes saved')
    yield put(fetchTermbases())
  } catch (e) {
    Logger.error(e, 'Changes could not be saved')
    yield put(updateTermbaseFailure())
  }
}

function* deleteTermbaseSaga(action: IAction<IDeleteTermbasePayload>) {
  try {
    const { payload } = action
    yield call(API.deleteTermbase, payload)
    yield put(deleteTermbaseSuccess())
    toastr.success('', 'Term base deleted')
    yield put(fetchTermbases())
  } catch (e) {
    Logger.error(e, 'Term base could not be deleted')
    yield put(deleteTermbaseFailure())
  }
}

function* updateTermbaseEntrySaga(action: IAction<IUpdateTermbaseEntryPayload>): Generator<any, any, any> {
  try {
    const { payload } = action
    yield call(API.updateTermbaseEntry, payload)
    yield put(updateTermbaseEntrySuccess())
    toastr.success('', 'Changes saved')

    const state = yield select()
    const fetchTermbaseEntriesPayload = getTermbaseEntriesPayload(state)

    yield put(fetchTermbaseEntries(fetchTermbaseEntriesPayload))
  } catch (e) {
    Logger.error(e, 'Changes could not be saved')
    yield put(updateTermbaseEntryFailure())
  }
}

function* addTermbaseEntrySaga(action: IAction<IUpdateTermbaseEntryPayload>): Generator<any, any, any> {
  try {
    const { payload } = action
    yield call(API.addTermbaseEntry, payload)
    yield put(addTermbaseEntrySuccess())
    toastr.success('', 'Term added')

    const state = yield select()
    const fetchTermbaseEntriesPayload = getTermbaseEntriesPayload(state)

    yield put(fetchTermbaseEntries(fetchTermbaseEntriesPayload))
  } catch (e) {
    Logger.error(e, 'Term could not be added')
    yield put(addTermbaseEntryFailure())
  }
}

function* deleteTermbaseEntrySaga(action: IAction<IDeleteTermbaseEntryPayload>): Generator<any, any, any> {
  try {
    const { payload } = action
    yield call(API.deleteTermbaseEntry, payload)
    yield put(deleteTermbaseEntrySuccess())
    toastr.success('', 'Term deleted')

    const state = yield select()
    const fetchTermbaseEntriesPayload = getTermbaseEntriesPayload(state)

    yield put(fetchTermbaseEntries(fetchTermbaseEntriesPayload))
  } catch (e) {
    Logger.error(e, 'Term could not be deleted')
    yield put(deleteTermbaseEntryFailure())
  }
}

const getTermbaseEntriesPayload = (state: any) => {
  const { searchTerm, activeTermbaseId, activePage } = state.termbases
  const hasSearchTerm = searchTerm && searchTerm.length > 0
  return {
    termbaseId: activeTermbaseId,
    page: activePage,
    ...(hasSearchTerm ? { searchTerm } : {}),
  }
}

function* termbaseSagas() {
  yield takeEvery(TermbaseActionTypes.FETCH_TERMBASES, fetchTermbasesSaga)
  yield takeEvery(TermbaseActionTypes.FETCH_TERMBASE_ENTRIES, fetchTermbaseEntriesSaga)
  yield takeEvery(TermbaseActionTypes.CREATE_TERMBASE, createTermbaseSaga)
  yield takeEvery(TermbaseActionTypes.UPDATE_TERMBASE, updateTermbaseSaga)
  yield takeEvery(TermbaseActionTypes.DELETE_TERMBASE, deleteTermbaseSaga)
  yield takeEvery(TermbaseActionTypes.UPDATE_TERMBASE_ENTRY, updateTermbaseEntrySaga)
  yield takeEvery(TermbaseActionTypes.ADD_TERMBASE_ENTRY, addTermbaseEntrySaga)
  yield takeEvery(TermbaseActionTypes.DELETE_TERMBASE_ENTRY, deleteTermbaseEntrySaga)
}

export default termbaseSagas
