import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  ICreateVocabularyPayload,
  IUpdateVocabularyPayload,
  IDeleteVocabularyPayload,
} from './types'

const config: AxiosRequestConfig = {
  headers: {
    'X-CSRF-Token': window.Checksub.token
  },
  responseType: 'json'
}

export function fetchVocabularies() {
  return axios.get(`/api/v1/vocabularies/`)
}

export function createVocabulary(payload: ICreateVocabularyPayload) {
  return axios.post(`/api/v1/vocabularies/`, convertToSnakeCase(payload), config)
}

export function updateVocabulary(payload: IUpdateVocabularyPayload) {
  return axios.put(`/api/v1/vocabularies/${payload.id}`, convertToSnakeCase(payload), config)
}

export function deleteVocabulary(payload: IDeleteVocabularyPayload) {
  return axios.delete(`/api/v1/vocabularies/${payload.id}`, config)
}
