import filter from 'lodash/filter'
import map from 'lodash/map'

import { createSelector } from 'reselect'

import {
  IGuideline,
  IGuidelineState
} from './types'

export const getGuidelines = (state: IGuidelineState): IGuideline[] => state.guidelines

export const getCaptionGuidelines = createSelector(
  [getGuidelines],
  (guidelines) => filter(guidelines, guideline => guideline.attributes.category === 'caption')
)

export const getTranslationGuidelines = createSelector(
  [getGuidelines],
  (guidelines) => filter(guidelines, guideline => guideline.attributes.category === 'translation')
)

export const getCaptionGuidelineNames = createSelector(
  [getCaptionGuidelines],
  (captionGuidelines) => map(captionGuidelines, captionGuideline => captionGuideline.attributes.name)
)

export const getCaptionGuidelineIds = createSelector(
  [getCaptionGuidelines],
  (captionGuidelines) => map(captionGuidelines, captionGuideline => captionGuideline.id)
)

export const getTranslationGuidelineNames = createSelector(
  [getTranslationGuidelines],
  (translationGuidelines) => map(translationGuidelines, translationGuideline => translationGuideline.attributes.name)
)

export const getTranslationGuidelineIds = createSelector(
  [getTranslationGuidelines],
  (translationGuidelines) => map(translationGuidelines, translationGuideline => translationGuideline.id)
)
