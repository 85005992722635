import React from 'react'

import CloseIcon from '@Images/close.svg'
import MagicIcon from '@Images/magic.svg'

import './Alert.scss'

interface AlertProps {
  readonly message: string
  readonly className?: string
  close(): void
}

const Alert: React.FC<AlertProps> = props => {
  return (
    <div className="Alert">
      <div className="Alert__left">
        <MagicIcon className="Alert__icon" />
        {props.message}
        <a href="https://support.checksub.com/get-started/quick-start/how-to-use-a-guideline" target="_blank" rel="noreferrer">
          Learn more
        </a>
      </div>

      <CloseIcon
        className="Alert__close"
        onClick={props.close}
      />
    </div>
  )
}

export default Alert
