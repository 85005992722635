import React, { useState, useEffect } from 'react'
import find from 'lodash/find'
import CustomizationForm from '@SiteComponents/CustomizationForm'

import {
  IVocabulary,
  ICreateVocabularyPayload,
  IUpdateVocabularyPayload,
  IDeleteVocabularyPayload,
} from '@SiteModules/Vocabulary/types'

import {
  ICreateCustomizationPayload,
  ISaveCustomizationPayload,
  IGuideline,
} from '@SiteModules/Guideline/types'


interface VocabulariesFormProps {
  readonly vocabularies: IVocabulary[]
  readonly loading: boolean
  readonly modifying: boolean
  createVocabulary(payload: ICreateVocabularyPayload): void
  updateVocabulary(payload: IUpdateVocabularyPayload): void
  deleteVocabulary(payload: IDeleteVocabularyPayload): void
}

const MAX_UTTERANCE_LENGTH = 100 // characters
const MAX_NAME_LENGTH = 100 // characters

const VocabulariesForm: React.FC<VocabulariesFormProps> = props => {
  const {
    vocabularies,
    loading,
    modifying
  } = props

  const [discardChangesPopupOpen, setDiscardChangesPopupOpen] = useState(false)
  const [delayedActionType, setDelayedActionType] = useState('')
  const [delayedActionItemIndex, setDelayedActionItemIndex] = useState<number | null>(null)

  const [activeVocabulary, setActiveVocabulary] = useState<IVocabulary | IGuideline | null>(null)
  const [editedUtterancesString, setEditedUtterancesString] = useState('')

  const [editedName, setEditedName] = useState<null | string>(null)
  const [utterancesEdited, setUtterancesEdited] = useState(false)

  const [hasSavedNameOnly, setHasSavedNameOnly] = useState(false)
  const [hasCreatedVocabulary, setHasCreatedVocabulary] = useState(false)

  const isBusy = loading || modifying
  const hasVocabularies = vocabularies.length > 0

  useEffect(() => {
    if (!loading) {
      if (hasCreatedVocabulary) {
        handleCreating()
      } else {
        handleLoadingUpdatingDeleting()
      }
      resetState()
    }
  }, [loading])

  const handleCreating = () => {
    const createdVocabulary = vocabularies.reduce((a, b) => {
      return (a.id > b.id) ? a : b
    })
    setActiveVocabulary(createdVocabulary)
    setEditedUtterancesString(createdVocabulary.attributes.utterances.join('\n'))
    setHasCreatedVocabulary(false)
  }

  const handleLoadingUpdatingDeleting = () => {
    if (!activeVocabulary) {
      const newActiveVocabulary = hasVocabularies ? vocabularies[0] : null
      setActiveVocabulary(newActiveVocabulary)

      const newEditedUtterancesString = newActiveVocabulary && newActiveVocabulary.attributes.utterances ? newActiveVocabulary.attributes.utterances.join('\n') : ''
      setEditedUtterancesString(newEditedUtterancesString)
    } else {
      const updatedActiveVocabulary = find(vocabularies, vocabulary => vocabulary.id === activeVocabulary.id)
      const newActiveVocabulary = updatedActiveVocabulary || (hasVocabularies ? vocabularies[0] : null)
      setActiveVocabulary(newActiveVocabulary)
      if (!hasSavedNameOnly) {
        setEditedUtterancesString(newActiveVocabulary ? newActiveVocabulary.attributes.utterances.join('\n') : '')
      }
    }
  }

  const resetState = () => {
    setEditedName(null)

    if (hasSavedNameOnly) {
      setHasSavedNameOnly(false)
    } else {
      setUtterancesEdited(false)
    }
  }

  const closeDiscardChangesPopup = () => {
    setDiscardChangesPopupOpen(false)
    setDelayedActionType('')
    setDelayedActionItemIndex(null)
  }

  const openDiscardChangesPopup = () => {
    setDiscardChangesPopupOpen(true)
  }

  const changeVocabulary = (vocabulary: IVocabulary | null) => {
    setActiveVocabulary(vocabulary)
    setEditedUtterancesString(vocabulary ? vocabulary.attributes.utterances.join('\n') : '')
    setUtterancesEdited(false)
  }

  const delayedAction = () => {
    if (delayedActionType === 'changeItem' && typeof(delayedActionItemIndex) === 'number') {
      const newVocabulary = find(vocabularies, vocabulary => vocabulary.id === delayedActionItemIndex)
      if (newVocabulary) {
        changeVocabulary(newVocabulary)
      }
    }

    if (delayedActionType === 'createNewItem') {
      props.createVocabulary({})
      setHasCreatedVocabulary(true)
    }

    if (delayedActionType === 'deleteItem' && activeVocabulary) {
      props.deleteVocabulary({ id: activeVocabulary.id })
    }
  }

  const handleCreate = (payload: ICreateCustomizationPayload) => {
    const { content } = payload

    props.createVocabulary({
      ...(content ? { utterances: content.split('\n') } : {})
    })

    setHasCreatedVocabulary(true)
  }

  const handleSave = (payload: ISaveCustomizationPayload) => {
    const { id, name, content } = payload
    const hasContentToSave = typeof(content) === 'string'

    props.updateVocabulary({
      id,
      ...(name ? { name } : {}),
      ...(hasContentToSave ? { utterances: content.split('\n') } : {})
    })

    setHasSavedNameOnly(!hasContentToSave)
  }

  const renderCustomizationForm = () => {
    const placeholderText = 'Type your words here. One line for one expression or word.'

    return (
      <CustomizationForm
        items={vocabularies}
        placeholderText={placeholderText}
        contentEdited={utterancesEdited}
        activeItem={activeVocabulary}
        editedContent={editedUtterancesString}
        editedName={editedName}
        openDiscardChangesPopup={openDiscardChangesPopup}
        closeDiscardChangesPopup={closeDiscardChangesPopup}
        setDelayedActionType={setDelayedActionType}
        setDelayedActionItemIndex={setDelayedActionItemIndex}
        setContentEdited={setUtterancesEdited}
        setActiveItem={setActiveVocabulary}
        setEditedContent={setEditedUtterancesString}
        setEditedName={setEditedName}
        isBusy={isBusy}
        maxContentLength={MAX_UTTERANCE_LENGTH}
        maxNameLength={MAX_NAME_LENGTH}
        create={handleCreate}
        save={handleSave}
        discardChangesPopupOpen={discardChangesPopupOpen}
        delayedAction={delayedAction}
        isVocabulary
      />
    )
  }

  return (
    <React.Fragment>
      {renderCustomizationForm()}
    </React.Fragment>
  )
}

export default VocabulariesForm
