import React from 'react'
import Button from '@SiteComponents/Button'
import { ISubtitle } from '@SiteContainers/ProjectVersionsList/types'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import axios from 'axios'

import './ExportationButtons.scss'

interface ExportationButtonsProps {
  readonly subtitles: ISubtitle[]
  readonly anyExportingSubtitleHasSpeakers: boolean
}

const ZIP_FILENAME = 'Checksub.zip'

const ExportationButtons: React.FC<ExportationButtonsProps> = props => {
  const {
    subtitles,
    anyExportingSubtitleHasSpeakers,
  } = props

  const onlyOneSubtitleSelected = subtitles.length === 1

  const downloadUrl = (format: string, subtitleId: string): string => {
    return `/api/v1/subtitles/${subtitleId}/download?format=${format}`
  }

  const shouldAllowVideoExportation = subtitles[0].attributes.category !== 'manual'

  const redirectToEditor = () => {
    window.location.href = subtitles ? `/projects/${subtitles[0].attributes.projectId}/project_versions/${subtitles[0].attributes.projectVersionId}/subtitles/${subtitles[0].id}/edit?exportation=true` : ''
  }

  const downloadAsZipFile = (event: React.MouseEvent<HTMLElement>) => {
    const button = event.target as HTMLElement
    const format = button.innerText.toLowerCase()
    // Zip files: https://yashodgayashan.medium.com/zip-files-in-react-30fb77fd6a58
    const zip = new JSZip()

    const axiosCalls = subtitles.map(subtitle => {
      const url = getUrl(subtitle, format)

      return (
        axios.get(url, { responseType: 'blob' })
      )
    })

    Promise.all(axiosCalls).then(responses => {
      responses.forEach(response => {
        // Get the filename: https://stackoverflow.com/questions/50642065/get-a-file-name-before-saving-it
        const headerLine = response.headers['content-disposition']
        const startFileNameIndex = headerLine.indexOf('"') + 1
        const endFileNameIndex = headerLine.lastIndexOf('"')
        const filename = headerLine.substring(startFileNameIndex, endFileNameIndex)

        zip.file(filename, response.data)
      })

      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, ZIP_FILENAME)
      })
    })
  }

  const getUrl = (subtitle: ISubtitle, format: string) => {
    let url = ''

    url = format === 'srt with speakers' ? (
      subtitle.attributes.hasSpeakers ? downloadUrl('srt_speaker_detection', subtitle.id) : downloadUrl('srt', subtitle.id)
    ) : (
      downloadUrl(format, subtitle.id)
    )

    return url
  }

  const renderButtonsForOneSelectedSubtitle = () => {
    return (
      <div className="ExportationButtons__buttons">
        {shouldAllowVideoExportation && (
          <Button
            caption="Video exportation"
            color="primary-dark"
            onClick={redirectToEditor}
          />
        )}
        <a className="ExportationButtons__buttons-button" href={downloadUrl('srt', subtitles[0].id)} download>SRT</a>
        {anyExportingSubtitleHasSpeakers && (
          <a className="ExportationButtons__buttons-button" href={downloadUrl('srt_speaker_detection', subtitles[0].id)} download>SRT with speakers</a>
        )}
        <a className="ExportationButtons__buttons-button" href={downloadUrl('vtt', subtitles[0].id)} download>VTT</a>
        <a className="ExportationButtons__buttons-button" href={downloadUrl('ttml', subtitles[0].id)} download>TTML</a>
        <a className="ExportationButtons__buttons-button" href={downloadUrl('csv', subtitles[0].id)} download>CSV</a>
        <a className="ExportationButtons__buttons-button" href={downloadUrl('avid', subtitles[0].id)} download>AVID</a>
        <a className="ExportationButtons__buttons-button" href={downloadUrl('itt', subtitles[0].id)} download>ITT</a>
        <a className="ExportationButtons__buttons-button" href={downloadUrl('txt', subtitles[0].id)} download>TXT</a>
        <a className="ExportationButtons__buttons-button" href={downloadUrl('teletext', subtitles[0].id)} download>TELETEXT</a>
        <a className="ExportationButtons__buttons-button" href={downloadUrl('ass', subtitles[0].id)} download>ASS</a>
        <a className="ExportationButtons__buttons-button" href={downloadUrl('ass_karaoke', subtitles[0].id)} download>ASS with karaoke</a>
      </div>
    )
  }

  const renderButtonsForMultipleSelectedSubtitles = () => {
    return (
      <div className="ExportationButtons__buttons">
        <a className="ExportationButtons__buttons-button" onClick={downloadAsZipFile}>SRT</a>
        {anyExportingSubtitleHasSpeakers && (
          <a className="ExportationButtons__buttons-button" onClick={downloadAsZipFile}>SRT with speakers</a>
        )}
        <a className="ExportationButtons__buttons-button" onClick={downloadAsZipFile}>VTT</a>
        <a className="ExportationButtons__buttons-button" onClick={downloadAsZipFile}>TTML</a>
        <a className="ExportationButtons__buttons-button" onClick={downloadAsZipFile}>CSV</a>
        <a className="ExportationButtons__buttons-button" onClick={downloadAsZipFile}>AVID</a>
        <a className="ExportationButtons__buttons-button" onClick={downloadAsZipFile}>ITT</a>
        <a className="ExportationButtons__buttons-button" onClick={downloadAsZipFile}>TXT</a>
        <a className="ExportationButtons__buttons-button" onClick={downloadAsZipFile}>TELETEXT</a>
        <a className="ExportationButtons__buttons-button" onClick={downloadAsZipFile}>ASS</a>
        <a className="ExportationButtons__buttons-button" onClick={downloadAsZipFile}>ASS with karaoke</a>
      </div>
    )
  }

  return(
    <div className="ExportationButtons">
      <h2 className="ExportationButtons__title">Choose the file format to export</h2>
      {onlyOneSubtitleSelected ? renderButtonsForOneSelectedSubtitle() : renderButtonsForMultipleSelectedSubtitles()}
    </div>
  )
}

export default ExportationButtons
