import React, { useState } from 'react'
import classnames from 'classnames'
import { v4 as uuidv4 } from 'uuid'

import './CheckboxV2.scss'

interface CheckboxV2Props {
  id?: string
  name?: string
  defaultChecked?: boolean
  checked?: boolean
  label?: string | JSX.Element
  disabled?: boolean
  customRef?: React.Ref<HTMLInputElement>
  tooltipForDisabledLine1?: string
  tooltipForDisabledLine2?: string
  tooltipForEnabledLine1?: string
  tooltipForEnabledLine2?: string
  labelIcon?: JSX.Element
  onChange?(checked: boolean): void
}

const CheckboxV2: React.FC<CheckboxV2Props> = props => {
  const {
    id,
    name,
    label,
    checked,
    defaultChecked,
    disabled,
    customRef,
    tooltipForDisabledLine1,
    tooltipForDisabledLine2,
    tooltipForEnabledLine1,
    tooltipForEnabledLine2,
    labelIcon,
  } = props

  const checkboxUuid = uuidv4()

  const [showInfo, setShowInfo] = useState(false)
  const [infoTimeout, setInfoTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled || !props.onChange) { return }

    props.onChange(e.target.checked)
  }

  const toggleShowInfo = () => {
    const shouldShowInfo =
      disabled && (tooltipForDisabledLine1 || tooltipForDisabledLine2) ||
      !disabled && (tooltipForEnabledLine1 || tooltipForEnabledLine2)

      if (!shouldShowInfo) { return }

      if (infoTimeout) {
        setShowInfo(false)
        clearTimeout(infoTimeout)
        setInfoTimeout(null)
      } else {
        const newInfoTimeout = setTimeout(() => {
          setShowInfo(true)
        }, window.EditorSources.tooltipDelay)
        setInfoTimeout(newInfoTimeout)
      }
    }

  const shouldShowInfoForDisabled = disabled && (tooltipForDisabledLine1 || tooltipForDisabledLine2) && showInfo
  const shouldShowInfoForEnabled = !disabled && (tooltipForEnabledLine1 || tooltipForEnabledLine2) && showInfo

  return (
    <div
      className={classnames(
        'CheckboxV2',
        { 'CheckboxV2__disabled': disabled }
      )}
      onMouseEnter={toggleShowInfo}
      onMouseLeave={toggleShowInfo}
    >
      <label className="CheckboxV2__label" htmlFor={id ?? `checkbox-${checkboxUuid}`}>
        <input
          type="checkbox"
          id={id ?? `checkbox-${checkboxUuid}`}
          name={name ?? `checkbox-${checkboxUuid}`}
          checked={checked}
          defaultChecked={defaultChecked}
          ref={customRef}
          onChange={handleChange}
        />
        <span className="CheckboxV2__checkmark"
        />
        {label && (
          <React.Fragment>
            <span className="CheckboxV2__label-text">
              {label}
            </span>
            {labelIcon && labelIcon}
          </React.Fragment>
        )}
      </label>

      {shouldShowInfoForDisabled && (
        <div className="CheckboxV2__tooltip">
          {tooltipForDisabledLine1}
          <br/>
          {tooltipForDisabledLine2}
        </div>
      )}

      {shouldShowInfoForEnabled && (
        <div className="CheckboxV2__tooltip">
          {tooltipForEnabledLine1}
          <br/>
          {tooltipForEnabledLine2}
        </div>
      )}
    </div>
  )
}

export default CheckboxV2
