import React, { useState } from 'react'
import classnames from 'classnames'

import Termbase from './Termbase'

import Arrow from '@Images/arrow_pv_settings.svg'

const DELAY_TO_SHOW_OVERFLOW = 200 // ms, The same time as for the opening/closing animations in ProjectVersionSettingsFormTts__options-body

interface GeneralOptionsProps {
  readonly termbaseNames: string[]
  readonly selectedTermbaseName?: string
  setSelectedTermbaseName(newTermbaseName?: string): void
}

const GeneralOptions: React.FC<GeneralOptionsProps> = props => {
  const {
    termbaseNames,
    selectedTermbaseName
  } = props

  const [bodyOpen, setBodyOpen] = useState(false)
  const [bodyOpenDelayed, setBodyOpenDelayed] = useState(false)

  const toggleBody = () => {
    // https://stackoverflow.com/questions/13938460/css-transition-auto-height-not-working
    const bodyDiv = document.getElementById('ProjectVersionSettingsFormTts__options-body-general')
    if (!bodyDiv) { return }

    bodyDiv.clientHeight ? closeBody(bodyDiv) : openBody(bodyDiv)
  }

  const openBody = (bodyDiv: HTMLElement) => {
    const wrapper = document.querySelector('.ProjectVersionSettingsFormTts__options-body-inner-wrapper-general')
    if (wrapper) {
      bodyDiv.style.height = wrapper.clientHeight + 'px'
      setBodyOpen(true)
      setTimeout(() => {
        setBodyOpenDelayed(true)
      }, DELAY_TO_SHOW_OVERFLOW)
    }
  }

  const closeBody = (bodyDiv: HTMLElement) => {
    bodyDiv.style.height = '0'
    setBodyOpen(false)
    setBodyOpenDelayed(false)
  }

  const renderBody = () => {
    return (
      <div
          className={classnames(
            'ProjectVersionSettingsFormTts__options-body',
            {'ProjectVersionSettingsFormTts__options-body-open': bodyOpenDelayed})
          }
          id="ProjectVersionSettingsFormTts__options-body-general"
        >
        <div className="ProjectVersionSettingsFormTts__options-body-inner-wrapper-general">
          <Termbase
            termbaseNames={termbaseNames}
            selectedTermbaseName={selectedTermbaseName}
            setSelectedTermbaseName={props.setSelectedTermbaseName}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="ProjectVersionSettingsFormTts__options">
      <div
        className="ProjectVersionSettingsFormTts__options-header"
        onClick={toggleBody}
      >
        <span>
          General
        </span>
        <Arrow
          className={classnames(
            'ProjectVersionSettingsFormTts__options-arrow',
            {'ProjectVersionSettingsFormTts__options-arrow-open': bodyOpen})
          }
        />
      </div>
      {renderBody()}
    </div>
  )
}

export default GeneralOptions
