import React, { useRef, useState, useEffect } from 'react'
import { Loadable } from '@Root/types'
import { useForm } from 'react-hook-form'
import uniq from 'lodash/uniq'
import map from 'lodash/map'
import findIndex from 'lodash/findIndex'
import without from 'lodash/without'
import difference from 'lodash/difference'
import filter from 'lodash/filter'
import some from 'lodash/some'
import toastr from 'toastr'
import convertFormality from '@Utils/ConvertFormality'
import getLanguagesThatDoNotAllowVoiceCloning from '@Utils/GetLanguagesThatDoNotAllowVoiceCloning'

import MainSettings from './MainSettings'
import Costs from './Costs'
import ScriptImport from './ScriptImport'
import ConfirmationButton from './ConfirmationButton'
import GeneralOptions from './GeneralOptions'
import TranscriptionOptions from './TranscriptionOptions'
import TranslationOptions from './TranslationOptions'
import DubbingOptions from './DubbingOptions'
import CreditsOptions from './CreditsOptions'

import Return from '@Images/return_from_pv_settings.svg'

import {
  PayloadSubmitProjectVersionUpdateBody,
} from '@SitePages/ProjectVersionSettings/types'

import {
  ICreditsRequired
} from '@SitePages/CreditsTopup/types'

import '@checksub_team/react-widgets/dist/css/react-widgets.css'
import '@Root/config/dropdowns.scss'
import './ProjectVersionSettingsForm.scss'

interface ProjectVersionSettingsFormProps extends Loadable {
  readonly availableCredits: number
  readonly vocabularyIds: number[]
  readonly vocabularyNames: string[]
  readonly captionGuidelineIds: number[]
  readonly captionGuidelineNames: string[]
  readonly translationGuidelineIds: number[]
  readonly translationGuidelineNames: string[]
  readonly termbaseNames: string[]
  readonly termbaseIds: number[]
  readonly projectTermbaseName?: string
  readonly lastUsedTermbaseName?: string
  readonly maxLanguagesLimit: number
  readonly languagesForVideos: string[]
  readonly translationLanguages: string[]
  readonly nonTranslatableLanguages: string[]
  readonly translationFormalityOptionLanguages: string[]
  readonly isSubscriptionCurrent: boolean
  readonly availableSubtitlingTime: number
  readonly subscriptionSubtitlesLength: number
  readonly projectVersionDuration: number
  readonly editingProjectVersionIds: string[]
  readonly subscriptionHasCreditLikeCharging: boolean
  readonly hasLoaded: boolean
  readonly lastUsedVocabularyName?: string
  readonly lastUsedCaptionGuidelineName?: string
  readonly lastUsedTranslationGuidelineName?: string
  readonly lastUsedSubtitleLanguages?: string[]
  readonly lastUsedTranscriptLanguage?: string
  readonly lastUsedReviewTranscript?: boolean
  readonly lastUsedSeparateAudio?: boolean
  readonly lastUsedAdaptTranslation?: boolean
  readonly lastTranscriptCreatedAfterGuidelineDeployment?: boolean
  readonly lastTranslationCreatedAfterGuidelineDeployment?: boolean
  readonly isAdmin: boolean
  readonly subscriptionAllowsVoiceAndVoiceCloning: boolean
  readonly voiceCloningLanguages: string[]
  readonly hasMainAssets: boolean
  readonly initialSubtitleLanguages: string[]
  readonly initialProjectVersionLanguage: string
  readonly alignmentLanguages: string[]
  readonly hasAudioSeparation: boolean
  readonly subscriptionAllowsTranslationAdaptionForDubbing: boolean
  readonly subscriptionAllowsLipSyncing: boolean
  readonly usingPersonalSubscription: boolean
  processNotEnoughCredits(payload: ICreditsRequired): void
  submitProjectVersionUpdate(payload: PayloadSubmitProjectVersionUpdateBody): void
}

type TFormData = {
  subtitleLanguages: string
  projectVersionLanguage: string
}

function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

const FACTOR_FOR_DUBBING = 3
const FACTOR_FOR_LIP_SYNCING_FOX = 3
const FACTOR_FOR_LIP_SYNCING_TIGER = 15
const DEFAULT_GUIDELINE_NAME = 'AI ENHANCEMENT'

const ProjectVersionSettingsForm: React.FC<ProjectVersionSettingsFormProps> = props => {
  const {
    isSubscriptionCurrent,
    projectVersionDuration,
    availableCredits,
    availableSubtitlingTime,
    maxLanguagesLimit,
    subscriptionSubtitlesLength,
    editingProjectVersionIds,
    alignmentLanguages,
    subscriptionHasCreditLikeCharging,
    hasLoaded,
    lastUsedTranscriptLanguage,
    lastUsedSubtitleLanguages,
    translationFormalityOptionLanguages,
    isAdmin,
    subscriptionAllowsVoiceAndVoiceCloning,
    voiceCloningLanguages,
    hasMainAssets,
    lastUsedReviewTranscript,
    lastUsedSeparateAudio,
    lastUsedAdaptTranslation,
    lastUsedVocabularyName,
    lastUsedCaptionGuidelineName,
    lastUsedTranslationGuidelineName,
    hasAudioSeparation,
    subscriptionAllowsTranslationAdaptionForDubbing,
    subscriptionAllowsLipSyncing,
    vocabularyNames,
    captionGuidelineNames,
    translationGuidelineNames,
    termbaseNames,
    vocabularyIds,
    captionGuidelineIds,
    translationGuidelineIds,
    termbaseIds,
    projectTermbaseName,
    lastUsedTermbaseName,
    lastTranscriptCreatedAfterGuidelineDeployment,
    lastTranslationCreatedAfterGuidelineDeployment
  } = props

  const { register, handleSubmit, getValues, errors } = useForm<TFormData>()

  const [projectVersionLanguage, setProjectVersionLanguage] = useState('')
  const [initialProjectVersionLanguage, setInitialProjectVersionLanguage] = useState('')
  const [subtitleLanguages, setSubtitleLanguages] = useState<string[]>([])
  const [disabledSubtitleLanguages, setDisabledSubtitleLanguages] = useState<string[]>([])
  const [onlyNewSubtitleLanguages, setOnlyNewSubtitleLanguages] = useState<string[]>([])
  const [languagesCorrectlySelected, setLanguagesCorrectlySelected] = useState(false)
  const [payloadSubmitProjectVersionUpdateBody, setPayloadSubmitProjectVersionUpdateBody] =
    useState<PayloadSubmitProjectVersionUpdateBody | null>(null)
  const [shouldShowTextAreaForTranscriptImport, setShouldShowTextAreaForTranscriptImport] = useState(false)
  const [transcriptText, setTranscriptText] = useState('')
  const [uploadScriptChecked, setUploadScriptChecked] = useState(false)
  const [noSpeakerDiarizationChecked, setNoSpeakerDiarizationChecked] = useState(false)
  const [formality, setFormality] = useState<string | undefined>()
  const [projectType, setProjectType] = useState('Subtitles')
  const [languagesThatDoNotAllowVoiceCloning, setLanguagesThatDoNotAllowVoiceCloning] = useState<string[]>([])
  const [reviewTranscriptChecked, setReviewTranscriptChecked] = useState(false)
  const [vocabularyChecked, setVocabularyChecked] = useState(false)
  const [selectedVocabularyName, setSelectedVocabularyName] = useState('')
  const [selectedCaptionGuidelineName, setSelectedCaptionGuidelineName] = useState('')
  const [selectedTranslationGuidelineName, setSelectedTranslationGuidelineName] = useState('')
  const [selectedTermbaseName, setSelectedTermbaseName] = useState('')
  const [selectedDubbingType, setSelectedDubbingType] = useState('Voice Cloning')
  const [separateAudioChecked, setSeparateAudioChecked] = useState(false)
  const [adaptTranslationChecked, setAdaptTranslationChecked] = useState(false)
  const [lipSyncChecked, setLipSyncChecked] = useState(false)
  const [lipSyncModel, setLipSyncModel] = useState('Fox')
  const [hasSrt, setHasSrt] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const openedForMultipleProjects = editingProjectVersionIds.length > 1
  const prevProjectVersionLanguage = usePrevious(projectVersionLanguage)

  let isLanguageLimitReached = false

  if (isSubscriptionCurrent) {
    isLanguageLimitReached = maxLanguagesLimit < (subscriptionSubtitlesLength + onlyNewSubtitleLanguages.length)
  }

  const isDubbingProject = projectType === 'Dubbing'
  const hasLanguagesThatDoNotAllowVoiceCloning = languagesThatDoNotAllowVoiceCloning.length > 0
  const willDubImmediately = isDubbingProject && !reviewTranscriptChecked && !hasLanguagesThatDoNotAllowVoiceCloning

  const subtitlesCostCredits = onlyNewSubtitleLanguages.length * projectVersionDuration - (hasSrt ? projectVersionDuration : 0)
  const isNewProject = !initialProjectVersionLanguage
  const numberOfNewTranslationSubtitles = isNewProject ? onlyNewSubtitleLanguages.length - 1 : onlyNewSubtitleLanguages.length
  const voiceCost = numberOfNewTranslationSubtitles * projectVersionDuration * FACTOR_FOR_DUBBING
  const isFox = lipSyncModel === 'Fox'
  const factorForLipSync = isFox ? FACTOR_FOR_LIP_SYNCING_FOX : FACTOR_FOR_LIP_SYNCING_TIGER
  const lipSyncCost = lipSyncChecked ? numberOfNewTranslationSubtitles * projectVersionDuration * factorForLipSync : 0
  const dubbingCost = willDubImmediately ? voiceCost + lipSyncCost : 0
  const totalCostCredits = subtitlesCostCredits + dubbingCost
  const hasNotEnoughCredits = availableCredits < totalCostCredits
  const cannotUseCredits = hasNotEnoughCredits
  const canUseCredits = !cannotUseCredits
  const hasNoSubscriptionSubtitles = subscriptionSubtitlesLength === 0
  const subtitlesCostSubscription = onlyNewSubtitleLanguages.length > 0 && hasNoSubscriptionSubtitles && !hasSrt ? projectVersionDuration : 0
  const totalCostSubscription = subtitlesCostSubscription // no cost for dubbing without credit-like charging in which case the totalCostCredit is used
  const hasEnoughSubtitlingTime = subscriptionHasCreditLikeCharging ? availableSubtitlingTime >= totalCostCredits : availableSubtitlingTime >= totalCostSubscription
  const canUseSubscriptionAlone = isSubscriptionCurrent && hasEnoughSubtitlingTime
  const hasEnoughCreditsWithSubscription = subscriptionHasCreditLikeCharging ?
    availableSubtitlingTime + availableCredits >= totalCostCredits :
    availableSubtitlingTime * onlyNewSubtitleLanguages.length + availableCredits >= totalCostCredits
  const canUseSubscriptionWithCredits = isSubscriptionCurrent && !hasEnoughSubtitlingTime && availableSubtitlingTime > 0 && hasEnoughCreditsWithSubscription

  const onlyNewTranslationLanguages = filter(onlyNewSubtitleLanguages, language => language !== projectVersionLanguage)
  const anyTranslationSubtitleWithFormalityOption = some(onlyNewTranslationLanguages, language => translationFormalityOptionLanguages.includes(language))
  const canSetFormality = anyTranslationSubtitleWithFormalityOption

  const canUploadTranscript = !!projectVersionLanguage && alignmentLanguages.includes(projectVersionLanguage)
  const areThereNewTranslationSubtitlesPresent = numberOfNewTranslationSubtitles > 0

  const useCredits = 'Use my credits'
  const useSubscriptionAndCredits = 'Use my subscription and credits in addition'
  const useSubscription = 'Use my subscription'

  let initialDebitOption = useCredits
  let initialDisableDebitDropdown = false
  let initialGenerateShortenedProjectVersion = false

  if (cannotUseCredits && canUseSubscriptionAlone) {
    initialDebitOption = useSubscription
  } else if (cannotUseCredits && canUseSubscriptionWithCredits) {
    initialDebitOption = useSubscriptionAndCredits
  } else if (cannotUseCredits) {
    initialDebitOption =  useSubscription
    initialDisableDebitDropdown = true
    if (isSubscriptionCurrent && isNewProject) {
      initialGenerateShortenedProjectVersion = true
    }
  }

  const [currentDebitOption, setCurrentDebitOption] = useState(initialDebitOption)
  const [disableDebitDropdown, setDisableDebitDropdown] = useState(initialDisableDebitDropdown)
  const [generateShortenedProjectVersion, setGenerateShortenedProjectVersion] = useState(initialGenerateShortenedProjectVersion)

  const canUseVocabularies = !uploadScriptChecked
  const canUseCaptionGuidelines = !uploadScriptChecked
  const showUploadOption = isNewProject && canUploadTranscript

  const dubImmediatelyWithNonSubscriptionPayment = willDubImmediately && currentDebitOption !== useSubscription
  const shouldHideCosts = (uploadScriptChecked && !shouldShowTextAreaForTranscriptImport) || dubImmediatelyWithNonSubscriptionPayment || generateShortenedProjectVersion || !projectVersionDuration

  const debitOptions: string[] = []
  if (canUseCredits) {
    debitOptions.push(useCredits)
  }

  if (canUseSubscriptionAlone) {
    debitOptions.push(useSubscription)
  } else if (canUseSubscriptionWithCredits) {
    debitOptions.push(useSubscriptionAndCredits)
  }

  if (debitOptions.length === 0) {
    debitOptions.push(useCredits)
  }

  const setSpeakerDiarizationChecked = (newSpeakerDiarizationChecked: boolean) => {
    setNoSpeakerDiarizationChecked(!newSpeakerDiarizationChecked)
  }

  useEffect(() => {
    setCurrentDebitOption(useCredits)
    setDisableDebitDropdown(false)
    setGenerateShortenedProjectVersion(false)

    if (cannotUseCredits && canUseSubscriptionAlone) {
      setCurrentDebitOption(useSubscription)
    } else if (cannotUseCredits && canUseSubscriptionWithCredits) {
      setCurrentDebitOption(useSubscriptionAndCredits)
    } else if (cannotUseCredits) {
      setCurrentDebitOption(useSubscription)
      setDisableDebitDropdown(true)
      if (isSubscriptionCurrent && isNewProject) {
        setGenerateShortenedProjectVersion(true)
      }
    }
  }, [cannotUseCredits, canUseSubscriptionWithCredits, canUseSubscriptionAlone])

  useEffect(() => {
    if (!hasLoaded) { return }

    if (props.initialProjectVersionLanguage) {
      setProjectVersionLanguage(props.initialProjectVersionLanguage)
      setInitialProjectVersionLanguage(props.initialProjectVersionLanguage)
    } else if (lastUsedTranscriptLanguage) {
      setProjectVersionLanguage(lastUsedTranscriptLanguage)
    }

    const uniqSubtitleLanguages = uniq(props.initialSubtitleLanguages)

    if (uniqSubtitleLanguages.length > 0) {
      setSubtitleLanguages(uniqSubtitleLanguages)
      setDisabledSubtitleLanguages(uniqSubtitleLanguages)
    } else if (lastUsedSubtitleLanguages) {
      setSubtitleLanguages(lastUsedSubtitleLanguages)
    }

    if (lastUsedReviewTranscript) {
      setReviewTranscriptChecked(lastUsedReviewTranscript)
    }

    if (lastUsedVocabularyName) {
      setSelectedVocabularyName(lastUsedVocabularyName)
      setVocabularyChecked(true)
    }

    if (lastUsedCaptionGuidelineName) {
      setSelectedCaptionGuidelineName(lastUsedCaptionGuidelineName)
    } else if (!lastTranscriptCreatedAfterGuidelineDeployment && captionGuidelineNames.includes(DEFAULT_GUIDELINE_NAME)) {
      setSelectedCaptionGuidelineName(DEFAULT_GUIDELINE_NAME)
    }

    if (lastUsedTranslationGuidelineName) {
      setSelectedTranslationGuidelineName(lastUsedTranslationGuidelineName)
    } else if (!lastTranslationCreatedAfterGuidelineDeployment && translationGuidelineNames.includes(DEFAULT_GUIDELINE_NAME)) {
      setSelectedTranslationGuidelineName(DEFAULT_GUIDELINE_NAME)
    }

    if (lastUsedSeparateAudio) {
      setSeparateAudioChecked(lastUsedSeparateAudio)
    }

    if (lastUsedAdaptTranslation) {
      setAdaptTranslationChecked(lastUsedAdaptTranslation)
    }

    const initialTermbaseName = projectTermbaseName || lastUsedTermbaseName

    if (initialTermbaseName) {
      setSelectedTermbaseName(initialTermbaseName)
    }

    const newLanguagesThatDoNotAllowVoiceCloning = getLanguagesThatDoNotAllowVoiceCloning(projectVersionLanguage, onlyNewSubtitleLanguages, voiceCloningLanguages)
    setLanguagesThatDoNotAllowVoiceCloning(newLanguagesThatDoNotAllowVoiceCloning)

    if (!projectVersionDuration) {
      toastr.error(
        'The duration of the video cannot be determined',
        'Unknown duration',
      )
    }
  }, [hasLoaded])

  useEffect(() => {
    if (!areThereNewTranslationSubtitlesPresent && isDubbingProject) {
      setProjectType('Subtitles')
    }

    const newLanguagesThatDoNotAllowVoiceCloning = getLanguagesThatDoNotAllowVoiceCloning(projectVersionLanguage, onlyNewSubtitleLanguages, voiceCloningLanguages)
    setLanguagesThatDoNotAllowVoiceCloning(newLanguagesThatDoNotAllowVoiceCloning)
  }, [projectVersionLanguage, onlyNewSubtitleLanguages])

  useEffect(() => {
    if (!projectVersionLanguage) { return }
    // If a projectVersion has a language we can pick up a new one, so there's no need
    // in the blocking mechanism.
    if (initialProjectVersionLanguage) { return }

    addToSubtitles()
    disableLanguages()
  }, [projectVersionLanguage])

  useEffect(() => {
    const newOnlyNewSubtitleLanguages = difference(subtitleLanguages, props.initialSubtitleLanguages)
    setOnlyNewSubtitleLanguages(newOnlyNewSubtitleLanguages)

    if (!areThereNewTranslationSubtitlesPresent && isDubbingProject) {
      setProjectType('Subtitles')
    }
  }, [subtitleLanguages])

  useEffect(() => {
    const isSubscriptionPaymentSelected = currentDebitOption === useSubscription || currentDebitOption === useSubscriptionAndCredits
    const areNewSubtitlesAdded = onlyNewSubtitleLanguages.length
    const isThereLanguagesLimit = maxLanguagesLimit > 0

    const shouldShowSubscriptionWarning = isLanguageLimitReached &&
      isThereLanguagesLimit &&
      areNewSubtitlesAdded &&
      isSubscriptionPaymentSelected

    if (shouldShowSubscriptionWarning) {
      toastr.error(
        `Your subscription allows you to create up to ${maxLanguagesLimit} subtitling languages. \
        Update your subscription or use additional credits for this project.`,
        'Subtitles limit reached',
      )
    }
  }, [currentDebitOption, onlyNewSubtitleLanguages])

  useEffect(() => {
    setLanguagesCorrectlySelected(!!projectVersionLanguage && onlyNewSubtitleLanguages.length > 0)
  }, [projectVersionLanguage, onlyNewSubtitleLanguages])

  const disableLanguages = () => {
    let currentDisabledSubtitleLanguages = disabledSubtitleLanguages.slice()
    currentDisabledSubtitleLanguages.unshift(projectVersionLanguage)
    currentDisabledSubtitleLanguages = uniq(without(currentDisabledSubtitleLanguages, prevProjectVersionLanguage || ''))
    setDisabledSubtitleLanguages(currentDisabledSubtitleLanguages)
  }

  const addToSubtitles = () => {
    // TODO: remove or comment the part --> '[]'
    const languages = getValues('subtitleLanguages') || '[]'
    let currentSubtitleLanguages = JSON.parse(languages)
    currentSubtitleLanguages.unshift(projectVersionLanguage)
    currentSubtitleLanguages = uniq(without(currentSubtitleLanguages, prevProjectVersionLanguage))
    setSubtitleLanguages(currentSubtitleLanguages)
  }

  const onSubmit = handleSubmit((_formData: TFormData) => {
    const totalCost = onlyNewSubtitleLanguages.length * projectVersionDuration
    const payWithCredits = currentDebitOption === useCredits

    if (totalCost > availableCredits && payWithCredits) {
      const requiredCredits =  totalCost - availableCredits
      props.processNotEnoughCredits({ requiredCredits })
      return
    }

    updateProjectVersion()
  })

  const onSubmitWithTranscript = () => {
    if (payloadSubmitProjectVersionUpdateBody) {
      setShouldShowTextAreaForTranscriptImport(false)
      props.submitProjectVersionUpdate({
        ...payloadSubmitProjectVersionUpdateBody,
        ...(hasSrt ? { srtTranscriptText: transcriptText } : { transcriptText }),
        enableSpeakerDiarization: !noSpeakerDiarizationChecked
      })
      setIsSubmitted(true)
    }
  }

  const updateProjectVersion = () => {
    const willUseVocabularies = vocabularyChecked && canUseVocabularies && selectedVocabularyName.length > 0 && selectedVocabularyName !== 'Do not use any list'
    const willUseCaptionGuideline = canUseCaptionGuidelines && selectedCaptionGuidelineName.length > 0 && selectedCaptionGuidelineName !== 'None'
    const willUseTranslationGuideline = selectedTranslationGuidelineName.length > 0 && selectedTranslationGuidelineName !== 'None'
    const willUseTermbase = selectedTermbaseName.length > 0 && selectedTermbaseName !== 'None'

    const automaticTranslation = areThereNewTranslationSubtitlesPresent
    const reviewTranscriptBeforeTranslation = areThereNewTranslationSubtitlesPresent && isNewProject ? reviewTranscriptChecked : false
    const useSubscriptionWithOrWithoutcredits = currentDebitOption === useSubscription || currentDebitOption === useSubscriptionAndCredits
    const paymentType = useSubscriptionWithOrWithoutcredits ? 'subscription' : 'essential'

    const dubbingSettings = {
      createCustomVoices: selectedDubbingType === 'Voice Cloning',
      shouldSeparateAudio: separateAudioChecked,
      adaptTranslation: subscriptionAllowsTranslationAdaptionForDubbing && adaptTranslationChecked,
      ...(subscriptionAllowsLipSyncing && lipSyncChecked ? { lipSyncModel } : {}),
    }

    const payload: PayloadSubmitProjectVersionUpdateBody = {
      projectVersionIds: editingProjectVersionIds,
      projectVersionLanguage,
      automaticTranslation,
      subtitleLanguages: onlyNewSubtitleLanguages,
      paymentType,
      vocabularyIds: willUseVocabularies ? findVocabularyIds([selectedVocabularyName]) : [],
      includesTranscript: isNewProject,
      reviewTranscriptBeforeTranslation,
      enableSpeakerDiarization: !noSpeakerDiarizationChecked,
      dubbing: willDubImmediately,
      generateShortenedProjectVersion,
      ...(canSetFormality && formality ? { formality: convertFormality(formality) } : {}),
      ...(currentDebitOption === useSubscriptionAndCredits ? { useCredits: true } : {}),
      ...(willDubImmediately ? dubbingSettings : {}),
      ...(willUseCaptionGuideline ? { captionGuidelineId: findGuidelineId(captionGuidelineNames, captionGuidelineIds, selectedCaptionGuidelineName) } : {}),
      ...(willUseTranslationGuideline ? { translationGuidelineId: findGuidelineId(translationGuidelineNames, translationGuidelineIds, selectedTranslationGuidelineName) } : {}),
      ...(willUseTermbase ? { termbaseId: findTermbaseId(selectedTermbaseName) } : {})
    }

    if (uploadScriptChecked && showUploadOption && !generateShortenedProjectVersion && !shouldShowTextAreaForTranscriptImport) {
      setShouldShowTextAreaForTranscriptImport(true)
      setPayloadSubmitProjectVersionUpdateBody(payload)
    } else {
      props.submitProjectVersionUpdate(payload)
      setIsSubmitted(true)
    }
  }

  const findVocabularyIds = (selectedVocabularyNames: string[]): number[] => {
    if (selectedVocabularyNames.length === 0) { return [] }

    const selectedVocabularyIds = map(selectedVocabularyNames, name => {
      const index = findIndex(vocabularyNames, vocabularyName => name === vocabularyName)
      return vocabularyIds[index]
    })

    return selectedVocabularyIds
  }

  const findGuidelineId = (guidelineNames: string[], guidelineIds: number[], guidelineNameToUse: string): number => {
    const index = findIndex(guidelineNames, guidelineName => guidelineName === guidelineNameToUse)

    return guidelineIds[index]
  }

  const findTermbaseId = (termbaseNameToUse: string): number => {
    const index = findIndex(termbaseNames, termbaseName => termbaseName === termbaseNameToUse)

    return termbaseIds[index]
  }

  const changeProjectVersionLanguage = (newProjectVersionLanguage: string) => {
    const wasTranslatable = !(projectVersionLanguage && props.nonTranslatableLanguages.includes(projectVersionLanguage))
    const isTranslatable = !(newProjectVersionLanguage && props.nonTranslatableLanguages.includes(newProjectVersionLanguage))

    if (isTranslatable !== wasTranslatable) { setSubtitleLanguages([]) }

    setProjectVersionLanguage(newProjectVersionLanguage)
  }

  const changeSubtitleLanguages = (newSubtitleLanguages: string[]) => {
    setSubtitleLanguages(newSubtitleLanguages)
  }

  const goToDashboarOrPreviousStep = () => {
    if (shouldShowTextAreaForTranscriptImport) {
      setShouldShowTextAreaForTranscriptImport(false)
      setPayloadSubmitProjectVersionUpdateBody(null)
    } else {
      window.location.href = '/'
    }
  }

  const renderMainSettings = () => {
    const { languagesForVideos, translationLanguages, nonTranslatableLanguages } = props

    const isTranslatable = !(projectVersionLanguage && nonTranslatableLanguages.includes(projectVersionLanguage))
    const shouldAllowDubbingProject = hasMainAssets && (subscriptionAllowsVoiceAndVoiceCloning || isAdmin) && areThereNewTranslationSubtitlesPresent
    const projectTypeOptions = shouldAllowDubbingProject ? ['Subtitles', 'Dubbing'] : ['Subtitles']

    return (
      <MainSettings
        errors={errors}
        register={register}
        projectVersionLanguage={projectVersionLanguage}
        changeSubtitleLanguages={changeSubtitleLanguages}
        isTranslatable={isTranslatable}
        subtitleLanguages={subtitleLanguages}
        languagesForVideos={languagesForVideos}
        changeProjectVersionLanguage={changeProjectVersionLanguage}
        initialProjectVersionLanguage={initialProjectVersionLanguage}
        translationLanguages={translationLanguages}
        disabledSubtitleLanguages={disabledSubtitleLanguages}
        hasLoaded={hasLoaded}
        projectType={projectType}
        changeProjectType={setProjectType}
        projectTypeOptions={projectTypeOptions}
      />
    )
  }

  const shouldRenderTranscriptionOptions = isNewProject
  const shouldRenderTranslationOptions = areThereNewTranslationSubtitlesPresent

  const shouldRenderDubbingOptions = isDubbingProject

  const renderAdvancedOptions = () => {
    return (
      <React.Fragment>
        <div className="ProjectVersionSettingsForm__title-advanced">
          Advanced options
        </div>
        {renderGeneralOptions()}
        {shouldRenderTranscriptionOptions && renderTranscriptionOptions()}
        {shouldRenderTranslationOptions && renderTranslationOptions()}
        {shouldRenderDubbingOptions && renderDubbingOptions()}
        {renderCreditsOptions()}
      </React.Fragment>
    )
  }

  const renderGeneralOptions = () => {
    return (
      <GeneralOptions
        termbaseNames={termbaseNames}
        selectedTermbaseName={selectedTermbaseName}
        setSelectedTermbaseName={setSelectedTermbaseName}
      />
    )
  }

  const renderTranscriptionOptions = () => {
    return (
      <TranscriptionOptions
        showUploadOption={showUploadOption}
        generateShortenedProjectVersion={generateShortenedProjectVersion}
        areThereNewTranslationSubtitlesPresent={areThereNewTranslationSubtitlesPresent}
        openedForMultipleProjects={openedForMultipleProjects}
        reviewTranscriptChecked={reviewTranscriptChecked}
        setReviewTranscriptChecked={setReviewTranscriptChecked}
        uploadScriptChecked={uploadScriptChecked}
        setUploadScriptChecked={setUploadScriptChecked}
        canUseVocabularies={canUseVocabularies}
        canUseGuidelines={canUseCaptionGuidelines}
        vocabularyNames={vocabularyNames}
        selectedVocabularyName={selectedVocabularyName}
        setSelectedVocabularyName={setSelectedVocabularyName}
        vocabularyChecked={vocabularyChecked}
        setVocabularyChecked={setVocabularyChecked}
        noSpeakerDiarizationChecked={noSpeakerDiarizationChecked}
        setNoSpeakerDiarizationChecked={setNoSpeakerDiarizationChecked}
        guidelineNames={captionGuidelineNames}
        selectedGuidelineName={selectedCaptionGuidelineName}
        setSelectedGuidelineName={setSelectedCaptionGuidelineName}
      />
    )
  }

  const renderTranslationOptions = () => {
    return (
      <TranslationOptions
        formality={formality}
        changeFormality={setFormality}
        guidelineNames={translationGuidelineNames}
        selectedGuidelineName={selectedTranslationGuidelineName}
        setSelectedGuidelineName={setSelectedTranslationGuidelineName}
        canSetFormality={canSetFormality}
      />
    )
  }

  const renderDubbingOptions = () => {
    return (
      <DubbingOptions
        hasLanguagesThatDoNotAllowVoiceCloning={hasLanguagesThatDoNotAllowVoiceCloning}
        reviewTranscriptChecked={reviewTranscriptChecked}
        selectedDubbingType={selectedDubbingType}
        setSelectedDubbingType={setSelectedDubbingType}
        separateAudioChecked={separateAudioChecked}
        setSeparateAudioChecked={setSeparateAudioChecked}
        adaptTranslationChecked={adaptTranslationChecked}
        setAdaptTranslationChecked={setAdaptTranslationChecked}
        lipSyncChecked={lipSyncChecked}
        setLipSyncModel={setLipSyncModel}
        setLipSyncChecked={setLipSyncChecked}
        hasAudioSeparation={hasAudioSeparation}
        subscriptionAllowsTranslationAdaptionForDubbing={subscriptionAllowsTranslationAdaptionForDubbing}
        subscriptionAllowsLipSyncing={subscriptionAllowsLipSyncing}
        isAdmin={isAdmin}
        lipSyncModel={lipSyncModel}
      />
    )
  }

  const renderCreditsOptions = () => {
    return (
      <CreditsOptions
        debitOptions={debitOptions}
        currentDebitOption={currentDebitOption}
        setCurrentDebitOption={setCurrentDebitOption}
        disableDebitDropdown={disableDebitDropdown}
        availableCredits={availableCredits}
        availableSubtitlingTime={availableSubtitlingTime}
      />
    )
  }

  const renderCosts = () => {
    return (
      <Costs
        currentDebitOption={currentDebitOption}
        shouldDisableDebitDropdown={disableDebitDropdown}
        totalCostCredits={totalCostCredits}
        totalCostSubscription={totalCostSubscription}
        availableCredits={availableCredits}
        availableSubtitlingTime={availableSubtitlingTime}
        subscriptionHasCreditLikeCharging={subscriptionHasCreditLikeCharging}
        numberOfNewSubtitles={onlyNewSubtitleLanguages.length}
        projectVersionDuration={projectVersionDuration}
        openedForMultipleProjects={openedForMultipleProjects}
        shouldHideCosts={shouldHideCosts}
      />
    )
  }

  const renderConfirmationButton = () => {
    const {
      showSpinner,
      disableButton,
      usingPersonalSubscription,
    } = props

    const willContinueWithScriptImportation = !shouldShowTextAreaForTranscriptImport && uploadScriptChecked
    const shouldRenderLinkToSubscription = usingPersonalSubscription && (disableDebitDropdown && !generateShortenedProjectVersion) && onlyNewSubtitleLanguages.length > 0
    const transcriptEmpty = shouldShowTextAreaForTranscriptImport && transcriptText.length === 0
    const disableConfirmationButton =
      disableButton ||
      !languagesCorrectlySelected ||
      transcriptEmpty ||
      dubImmediatelyWithNonSubscriptionPayment ||
      (!willContinueWithScriptImportation && disableDebitDropdown && !generateShortenedProjectVersion) ||
      !projectVersionDuration ||
      isSubmitted

    return (
      <ConfirmationButton
        showSpinner={showSpinner}
        disableButton={disableConfirmationButton}
        projectVersionLanguage={projectVersionLanguage}
        isLanguageLimitReached={isLanguageLimitReached}
        onlyNewSubtitleLanguages={onlyNewSubtitleLanguages}
        isPaidBySubscription={currentDebitOption === useSubscription || currentDebitOption === useSubscriptionAndCredits}
        shouldRenderLinkToSubscription={!willContinueWithScriptImportation && shouldRenderLinkToSubscription}
        defaultCaption={willContinueWithScriptImportation ? 'Next' : 'Confirm'}
        shouldShowTooltip={dubImmediatelyWithNonSubscriptionPayment}
        tooltipText="Dubbing only possible with subscription payment"
        shouldDisableDebitDropdown={disableDebitDropdown}
        generateShortenedProjectVersion={generateShortenedProjectVersion}
      />
    )
  }

  const renderScriptImport = () => {
    return (
      <form
        className="ProjectVersionSettingsForm ProjectVersionSettingsForm__import"
        onSubmit={onSubmitWithTranscript}
      >
        <div className="ProjectVersionSettingsForm__import-title">
          Import script/SRT
        </div>
        <ScriptImport
          hasSrt={hasSrt}
          setHasSrt={setHasSrt}
          setTranscriptText={setTranscriptText}
          speakerDiarizationChecked={!noSpeakerDiarizationChecked}
          setSpeakerDiarizationChecked={setSpeakerDiarizationChecked}
        />
        {renderConfirmationButton()}
        {transcriptText && renderCosts()}
      </form>
    )
  }

  const renderBackButton = () => {
    return (
      <div
        className="ProjectVersionSettingsForm__return"
        onClick= {goToDashboarOrPreviousStep}
      >
        <Return />
      </div>
    )
  }

  return (
    <React.Fragment>
      {renderBackButton()}
      {shouldShowTextAreaForTranscriptImport ? (
        renderScriptImport()
      ) : (
        <React.Fragment>
          <form className="ProjectVersionSettingsForm" onSubmit={onSubmit}>
            <div className="ProjectVersionSettingsForm__left">
              <div className="ProjectVersionSettingsForm__left-wrapper">
                <div className="ProjectVersionSettingsForm__left-top">
                  <div className="ProjectVersionSettingsForm__title">
                    Fine-tune your project(s)
                  </div>
                  {renderMainSettings()}
                </div>
                <div  className="ProjectVersionSettingsForm__left-bottom">
                  {renderConfirmationButton()}
                  {renderCosts()}
                </div>
              </div>
            </div>
            <div className="ProjectVersionSettingsForm__right">
              {renderAdvancedOptions()}
            </div>
          </form>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default ProjectVersionSettingsForm
