import {
  VocabularyActionTypes,
  IFetchVocabulariesSuccessResponseBody,
  ICreateVocabularyPayload,
  IUpdateVocabularyPayload,
  IDeleteVocabularyPayload,
} from './types'

export const fetchVocabularies = () => ({
  type: VocabularyActionTypes.FETCH_VOCABULARIES,
})

export const fetchVocabulariesFailure = () => ({
  type: VocabularyActionTypes.FETCH_VOCABULARIES_FAILURE,
})

export const fetchVocabulariesSuccess = (payload: IFetchVocabulariesSuccessResponseBody) => ({
  type: VocabularyActionTypes.FETCH_VOCABULARIES_SUCCESS,
  payload,
})

export const createVocabulary = (payload: ICreateVocabularyPayload) => ({
  type: VocabularyActionTypes.CREATE_VOCABULARY,
  payload,
})

export const createVocabularyFailure = () => ({
  type: VocabularyActionTypes.CREATE_VOCABULARY_FAILURE,
})

export const createVocabularySuccess = () => ({
  type: VocabularyActionTypes.CREATE_VOCABULARY_SUCCESS,
})

export const updateVocabulary = (payload: IUpdateVocabularyPayload) => ({
  type: VocabularyActionTypes.UPDATE_VOCABULARY,
  payload,
})

export const updateVocabularyFailure = () => ({
  type: VocabularyActionTypes.UPDATE_VOCABULARY_FAILURE,
})

export const updateVocabularySuccess = () => ({
  type: VocabularyActionTypes.UPDATE_VOCABULARY_SUCCESS,
})

export const deleteVocabulary = (payload: IDeleteVocabularyPayload) => ({
  type: VocabularyActionTypes.DELETE_VOCABULARY,
  payload,
})

export const deleteVocabularyFailure = () => ({
  type: VocabularyActionTypes.DELETE_VOCABULARY_FAILURE,
})

export const deleteVocabularySuccess = () => ({
  type: VocabularyActionTypes.DELETE_VOCABULARY_SUCCESS,
})
