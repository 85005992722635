import { IActionSuccess } from '@Root/types'

export const GuidelineActionTypes = {
  FETCH_GUIDELINES: '@@guideline/FETCH_GUIDELINES',
  FETCH_GUIDELINES_SUCCESS: '@@guideline/FETCH_GUIDELINES_SUCCESS',
  FETCH_GUIDELINES_FAILURE: '@@guideline/FETCH_GUIDELINES_FAILURE',
  CREATE_GUIDELINE: '@@guideline/CREATE_GUIDELINE',
  CREATE_GUIDELINE_SUCCESS: '@@guideline/CREATE_GUIDELINE_SUCCESS',
  CREATE_GUIDELINE_FAILURE: '@@guideline/CREATE_GUIDELINE_FAILURE',
  UPDATE_GUIDELINE: '@@guideline/UPDATE_GUIDELINE',
  UPDATE_GUIDELINE_SUCCESS: '@@guideline/UPDATE_GUIDELINE_SUCCESS',
  UPDATE_GUIDELINE_FAILURE: '@@guideline/UPDATE_GUIDELINE_FAILURE',
  DELETE_GUIDELINE: '@@guideline/DELETE_GUIDELINE',
  DELETE_GUIDELINE_SUCCESS: '@@guideline/DELETE_GUIDELINE_SUCCESS',
  DELETE_GUIDELINE_FAILURE: '@@guideline/DELETE_GUIDELINE_FAILURE',
  UPDATE_DEFAULT_GUIDELINE: '@@guideline/UPDATE_DEFAULT_GUIDELINE',
  UPDATE_DEFAULT_GUIDELINE_SUCCESS: '@@guideline/UPDATE_DEFAULT_GUIDELINE_SUCCESS',
  UPDATE_DEFAULT_GUIDELINE_FAILURE: '@@guideline/UPDATE_DEFAULT_GUIDELINE_FAILURE',
  DELETE_DEFAULT_GUIDELINE: '@@guideline/DELETE_DEFAULT_GUIDELINE',
  DELETE_DEFAULT_GUIDELINE_SUCCESS: '@@guideline/DELETE_DEFAULT_GUIDELINE_SUCCESS',
  DELETE_DEFAULT_GUIDELINE_FAILURE: '@@guideline/DELETE_DEFAULT_GUIDELINE_FAILURE',
}

export interface IGuidelineState {
  readonly guidelines: IGuideline[]
  readonly loading: boolean
  readonly modifying: boolean
}

export interface IGuideline {
  readonly id: number
  readonly attributes: IGuidelineAttributes
}

export interface IGuidelineAttributes {
  readonly category: string
  readonly name: string
  readonly prompt: string
  readonly isDefault: boolean
  readonly parameters: IGuidelineParameters
}

export interface IGuidelineParameters {
  readonly numberOfLines: number
  readonly cpl: number
  readonly gap: number
  readonly cps: number
  readonly useNumberOfLines: boolean
  readonly useCpl: boolean
  readonly useGap: boolean
  readonly useCps: boolean
  readonly [key: string]: number | boolean
}

export interface IGuidelineData {
  readonly id: string
  readonly type: 'guideline'
  readonly attributes: IGuidelineAttributes
}

export interface IFetchGuidelinesSuccessResponseBody {
  readonly data: IGuidelineData[]
}

export interface ICreateGuidelinePayload {
  readonly category: string
  readonly prompt?: string
  readonly parameters: IGuidelineParameters
}

export interface IUpdateGuidelinePayload {
  readonly id: number
  readonly name?: string
  readonly prompt?: string
  readonly parameters?: IGuidelineParameters
}

export interface IDeleteGuidelinePayload {
  readonly id: number
}

export interface IUpdateDefaultGuidelinePayload {
  readonly id: number
  readonly name: string
  readonly prompt: string
  readonly parameters: IGuidelineParameters
  readonly category: string
  readonly hasSavedNameOnly: boolean
}

export interface IDeleteDefaultGuidelinePayload {
  readonly id: number
}

export interface ICreateCustomizationPayload {
  readonly content?: string
  readonly parameters?: IGuidelineParameters
}

export interface ISaveCustomizationPayload {
  readonly id: number
  readonly name?: string
  readonly content?: string
  readonly parameters?: IGuidelineParameters
  readonly defaultGuideline?: IGuideline
}

export interface IParameter {
  readonly name: string
  readonly title: string
  readonly unit: string
  readonly minValue: number
  readonly maxValue: number
  readonly isFloat?: boolean
  readonly step?: number
}

export type TGuidelineActionTypes =
  IActionSuccess<IFetchGuidelinesSuccessResponseBody>
