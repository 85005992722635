import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import classnames from 'classnames'

import './InputForFloatAsText.scss'

const ENTER_KEY = 13
const ARROW_UP_KEY = 38
const ARROW_DOWN_KEY = 40

interface InputForFloatAsTextProps {
  readonly min: number
  readonly max: number
  readonly step: number
  readonly value: number
  readonly defaultValue: number
  readonly unit?: string
  readonly tooltip?: string
  readonly disabled?: boolean
  readonly numberOfDigits?: number
  readonly hasHorizontalCursor?: boolean
  readonly isForSite?: boolean
  onChange(newValue: number): void
}

const DEFAULT_NUMBER_OF_DIGITS = 2

const InputForFloatAsText: React.FC<InputForFloatAsTextProps> = props => {
  const {
    min,
    max,
    step,
    unit,
    value,
    tooltip,
    disabled,
    isForSite,
    defaultValue,
    hasHorizontalCursor,
  } = props

  const numberOfDigits = typeof(props.numberOfDigits) === 'number' ? props.numberOfDigits : DEFAULT_NUMBER_OF_DIGITS
  const tooltipDelay = isForSite ? window.Checksub.tooltipDelay : window.EditorSources.tooltipDelay

  const [showInfo, setShowInfo] = useState(false)
  const [infoTimeout, setInfoTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)
  const [inputValue, _setInputValue] = useState(value.toFixed(numberOfDigits))
  const [inputUuid, _setInputUuid] = useState(uuidv4())
  const inputValueRef = useRef(inputValue)
  const [isFocused, _setIsFocused] = useState(false)
  const isFocusedRef = useRef(isFocused)

  const setIsFocused = (newIsFocused: boolean) => {
    isFocusedRef.current = newIsFocused
    _setIsFocused(newIsFocused)
  }

  const setInputValue = (newInputValue: string) => {
    inputValueRef.current = newInputValue
    _setInputValue(newInputValue)
  }

  useEffect(() => {
    document.addEventListener('keydown', useSpecialKeys)

    return () => {
      document.removeEventListener('keydown', useSpecialKeys)
    }
  }, [])

  useEffect(() => {
    setInputValue(value.toFixed(numberOfDigits))
  }, [value])

  const shouldShowInfo = tooltip && showInfo && !disabled
  const shouldShowUnit = unit

  const toggleShowInfo = () => {
    if (!tooltip) { return }

    if (infoTimeout) {
      setShowInfo(false)
      clearTimeout(infoTimeout)
      setInfoTimeout(null)
    } else {
      const newInfoTimeout = setTimeout(() => {
        setShowInfo(true)
      }, tooltipDelay)
      setInfoTimeout(newInfoTimeout)
    }
  }

  const useSpecialKeys = (e: KeyboardEvent) => {
    if (!isFocusedRef.current) { return }

    const newValue = parseFloat(parseFloat(inputValueRef.current).toFixed(numberOfDigits))
    const isNaN = newValue !== newValue // https://medium.com/coding-in-simple-english/how-to-check-for-nan-in-javascript-4294e555b447#:~:text=In%20JavaScript%2C%20the%20best%20way,NaN%20will%20always%20return%20true%20.
    const isANumber = !isNaN

    switch(e.keyCode) {
      case ENTER_KEY:
        const input = document.getElementById(`InputForFloatAsText-input-${inputUuid}`)
        if (input) {
          input.blur()
        }
        break
      case ARROW_UP_KEY:
        if (isANumber) {
          setInputValue((newValue + step).toFixed(numberOfDigits))
        }
        break
      case ARROW_DOWN_KEY:
        if (isANumber) {
          setInputValue((newValue - step).toFixed(numberOfDigits))
        }
        break
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)

    const newValue = parseFloat(parseFloat(inputValue).toFixed(numberOfDigits))
    const isNaN = newValue !== newValue // https://medium.com/coding-in-simple-english/how-to-check-for-nan-in-javascript-4294e555b447#:~:text=In%20JavaScript%2C%20the%20best%20way,NaN%20will%20always%20return%20true%20.
    const isANumber = !isNaN
    const isTooSmall = isANumber && newValue < min
    const isTooLarge = isANumber && newValue > max

    if (isNaN) {
      changeToValue(defaultValue)
    } else if (isTooSmall) {
      changeToValue(min)
    } else if (isTooLarge) {
      changeToValue(max)
    } else {
      changeToValue(newValue)
    }
  }

  const changeToValue = (newValue: number) => {
    props.onChange(newValue)
    setInputValue(newValue.toFixed(numberOfDigits))
  }

  const renderUnit = () => {
    return (
      <span className="InputForFloatAsText__unit">
        {unit}
      </span>
    )
  }

  const renderInput = () => {
    return (
      <input
        type="text"
        id={`InputForFloatAsText-input-${inputUuid}`}
        name={`input-for-float-as-text-${inputUuid}`}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={classnames(
          'InputForFloatAsText__input',
          {'InputForFloatAsText__input-with-unit': shouldShowUnit}
        )}
        disabled={disabled}
      />
    )
  }

  const renderInfo = () => {
    return (
      <div className="InputForFloatAsText__tooltip">
        {tooltip}
      </div>
    )
  }

  return (
    <span
      className={classnames(
        'InputForFloatAsText',
        {'InputForFloatAsText__cursor-vertical': !hasHorizontalCursor},
        {'InputForFloatAsText__cursor-horizontal': hasHorizontalCursor},
        {'InputForFloatAsText-disabled': disabled}
      )}
      onMouseEnter={toggleShowInfo}
      onMouseLeave={toggleShowInfo}
    >
      {renderInput()}
      {unit && renderUnit()}
      {shouldShowInfo && renderInfo()}
    </span>
  )
}

export default InputForFloatAsText
